export default {
    ALL: 'ALL',
    DO_FOLLOW: 'DO_FOLLOW',
    NO_FOLLOW: 'NO_FOLLOW',
};

export const ALL = {
    id: 0,
    label: 'Do/NoFollow',
    value: 'ALL',
};

export const ALL_ALT = {
    id: 0,
    label: 'All',
    value: 'ALL',
};

export const DO_FOLLOW = {
    id: 1,
    label: 'DoFollow',
    value: 'DO_FOLLOW',
};

export const NO_FOLLOW = {
    id: 2,
    label: 'NoFollow',
    value: 'NO_FOLLOW',
};

export const LINK_REL_LIST = [ALL, DO_FOLLOW, NO_FOLLOW];
export const LINK_REL_LIST_ALT = [ALL_ALT, DO_FOLLOW, NO_FOLLOW];
