import update from 'immutability-helper';
import { REHYDRATE } from 'redux-persist/constants';
import { isNil } from 'ramda';

import FilterService from 'services/FilterService';

import ActionTypes from 'constants/ActionTypes';
import { ALL as ALL_LINK_RELS } from 'constants/LinkRelTypes';
import { ALL as ALL_LINK_STATUSES } from 'constants/LinkStatusTypes';
import { dashboardFilterSettings } from 'constants/Filters';

const initialState = {
    settings: FilterService.createInitialStoreState(dashboardFilterSettings),
    quickSettings: {
        linkRelType: ALL_LINK_RELS,
        linkStatusType: ALL_LINK_STATUSES,
        search: '',
    },
    visibility: false,
    advancedFilterActive: false,
};

const filterReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UI_FILTER_VISIBILITY_TOGGLE: {
            return update(state, {
                visibility: {
                    $apply: visibility => !visibility,
                },
            });
        }
        case ActionTypes.UI_FILTER_VISIBILITY_SET: {
            return update(state, {
                visibility: {
                    $set: action.payload,
                },
            });
        }
        case ActionTypes.UI_FILTER_QUICK_LINK_REL_TYPE_SET: {
            return update(state, {
                quickSettings: {
                    linkRelType: { $set: action.payload },
                },
            });
        }
        case ActionTypes.UI_FILTER_QUICK_LINK_STATUS_TYPE_SET: {
            return update(state, {
                quickSettings: {
                    linkStatusType: { $set: action.payload },
                },
            });
        }
        case ActionTypes.UI_FILTER_ADVANCED_ACTIVE_SET: {
            return update(state, {
                advancedFilterActive: {
                    $set: action.payload,
                },
            });
        }
        case ActionTypes.UI_FILTER_SETTINGS_SET: {
            return update(state, {
                settings: {
                    $set: action.payload,
                },
                advancedFilterActive: { $set: true },
                visibility: {
                    $set: false,
                },
            });
        }
        case ActionTypes.UI_FILTER_QUICK_SEARCH_SET: {
            return update(state, {
                quickSettings: {
                    search: { $set: action.payload },
                },
            });
        }
        case ActionTypes.UI_DASHBOARD_RESET: {
            return update(state, {
                quickSettings: {
                    linkRelType: { $set: initialState.quickSettings.linkRelType },
                    linkStatusType: { $set: initialState.quickSettings.linkStatusType },
                    search: { $set: initialState.quickSettings.search },
                },
                advancedFilterActive: { $set: false },
                visibility: {
                    $set: false,
                },
            });
        }
        case ActionTypes.UI_FILTER_RESET: {
            return update(state, {
                settings: {
                    $set: initialState.settings,
                },
                advancedFilterActive: { $set: false },
                visibility: {
                    $set: false,
                },
            });
        }
        case REHYDRATE: {
            const savedState = action.payload.ui;

            if (!isNil(savedState) && !isNil(savedState.filter)) {
                return update(state, {
                    settings: {
                        anchorFilter: {
                            $set: savedState.filter.settings.anchorFilter || initialState.settings.anchorFilter,
                        },
                        hrefFilter: {
                            $set: savedState.filter.settings.hrefFilter || initialState.settings.hrefFilter,
                        },
                        maxTopRank: {
                            $set: savedState.filter.settings.maxTopRank || initialState.settings.maxTopRank,
                        },
                        maxCitationFlow: {
                            $set: savedState.filter.settings.maxCitationFlow || initialState.settings.maxCitationFlow,
                        },
                        maxExternalLinks: {
                            $set: savedState.filter.settings.maxExternalLinks || initialState.settings.maxExternalLinks,
                        },
                        maxLinkStrength: {
                            $set: savedState.filter.settings.maxLinkStrength || initialState.settings.maxLinkStrength,
                        },
                        maxTrustFlow: {
                            $set: savedState.filter.settings.maxTrustFlow || initialState.settings.maxTrustFlow,
                        },
                        minTopRank: {
                            $set: savedState.filter.settings.minTopRank || initialState.settings.minTopRank,
                        },
                        minCitationFlow: {
                            $set: savedState.filter.settings.minCitationFlow || initialState.settings.minCitationFlow,
                        },
                        minExternalLinks: {
                            $set: savedState.filter.settings.minExternalLinks || initialState.settings.minExternalLinks,
                        },
                        minLinkStrength: {
                            $set: savedState.filter.settings.minLinkStrength || initialState.settings.minLinkStrength,
                        },
                        minTrustFlow: {
                            $set: savedState.filter.settings.minTrustFlow || initialState.settings.minTrustFlow,
                        },
                        urlFilter: {
                            $set: savedState.filter.settings.urlFilter || initialState.settings.urlFilter,
                        },
                    },
                });
            } else {
                return state;
            }
        }
        default: {
            return state;
        }
    }
};

export default filterReducer;
