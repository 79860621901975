import reverse from 'ramda/src/reverse';
import DataSourceTypes from 'constants/DataSourceTypes';

function parseSource(rawSource) {
    switch (rawSource) {
        case 0: {
            return DataSourceTypes.ALL_LINKS;
        }
        case 1: {
            return DataSourceTypes.NEW_LINKS;
        }
        case 2: {
            return DataSourceTypes.LOST_LINKS;
        }
        default: {
            return DataSourceTypes.ALL_LINKS;
        }
    }
}

export const parse = rawData =>
    reverse(
        rawData.map(item => ({
            id: item._id, // eslint-disable-line no-underscore-dangle
            linksPerDomain: parseInt(item.links_per_domain, 10),
            source: parseSource(item.source),
            url: item.url,
        })),
    );
