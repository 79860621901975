const EXACT_URL = 'Exact URL';
const ROOT_DOMAIN = 'Root domain';
const SUBDOMAIN = 'Subdomain';
const PATH_URL = 'Path URL*';

export default {
    EXACT_URL,
    ROOT_DOMAIN,
    SUBDOMAIN,
    PATH_URL,
};

export const ExportUrlTypes = {
    [ROOT_DOMAIN]: {
        icon: 'folder',
        title: 'Domain',
        description: 'Export ALL the backlinks for the domain and its sub domains.',
        url: 'example.com',
    },
    [SUBDOMAIN]: {
        icon: ['fac', 'folders'],
        title: 'Sub domain',
        description: 'Export all the backlinks for a given subdomain.',
        url: 'www.example.com',
    },
    [EXACT_URL]: {
        icon: 'bullseye',
        title: 'Exact URL',
        description: 'Export all the backlinks for the exact URL entered above.',
        url: 'https://example/com',
    },
};
