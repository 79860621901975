import reverse from 'ramda/src/reverse';
import sortBy from 'ramda/src/sortBy';
import prop from 'ramda/src/prop';
import isNil from 'ramda/src/isNil';

import SortingTypes from 'constants/SortingTypes';
import SortingColumns from 'constants/SortingColumns';

/**
 * Sorting service for result list data.
 */
class ResultSorterService {
    /**
     * Main sort function.
     *
     * @param {data: Array} Array of data.
     * @param {settings: Object} Sorting column and type.
     * @return {Array} Sorted data.
     */
    static sort({ data, settings }) {
        const attribute = settings.column;
        const reversed = this.getReversedFlag(settings.type, attribute);

        if (data.length === 0) {
            return data;
        } else if (isNil(attribute) || attribute === SortingColumns.BACKLINK_URL) {
            if (reversed === false) {
                return data;
            } else {
                return reverse(data);
            }
        } else {
            const sorted = sortBy(prop(attribute))(data);

            if (reversed === true) {
                return reverse(sorted);
            } else {
                return sorted;
            }
        }
    }

    static getReversedFlag(type, attribute) {
        const reversed = type === SortingTypes.DESC;
        // Flip reversed when we deal with BACKLINK_URL.
        return attribute === SortingColumns.BACKLINK_URL ? !reversed : reversed;
    }
}

export default ResultSorterService;
