import ActionTypes from 'constants/ActionTypes';
import update from 'immutability-helper';

const initialState = {
    app: {
        visibility: false,
    },
    favorites: {
        visibility: false,
    },
    history: {
        visibility: false,
    },
};

const addKeywordPanelReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UI_PANELS_APP_SHOW: {
            return update(state, {
                app: {
                    visibility: { $set: true },
                },
            });
        }
        case ActionTypes.UI_PANELS_APP_CLOSE: {
            return update(state, {
                app: {
                    visibility: { $set: false },
                },
            });
        }
        case ActionTypes.UI_PANELS_FAVORITES_SHOW: {
            return update(state, {
                favorites: {
                    visibility: { $set: true },
                },
            });
        }
        case ActionTypes.UI_PANELS_FAVORITES_CLOSE: {
            return update(state, {
                favorites: {
                    visibility: { $set: false },
                },
            });
        }
        case ActionTypes.UI_PANELS_HISTORY_SHOW: {
            return update(state, {
                history: {
                    visibility: { $set: true },
                },
            });
        }
        case ActionTypes.UI_PANELS_HISTORY_CLOSE: {
            return update(state, {
                history: {
                    visibility: { $set: false },
                },
            });
        }
        case ActionTypes.UI_ALL_CLOSE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default addKeywordPanelReducer;
