import React from 'react';
import { func, node, string, bool } from 'prop-types';
import isNil from 'ramda/src/isNil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFocusOnMount } from 'mangools-react-components';
import classnames from 'classnames';

const MESSAGE_HOLDER_ID = 'MESSAGE_HOLDER_ID';

const MessageHolder = React.memo(props => {
    const closeRef = props.preventCloseButtonFocus === true ? null : useFocusOnMount();

    const closeAll = e => {
        if (e.target.id === MESSAGE_HOLDER_ID) {
            if (!isNil(props.onClose)) {
                props.onClose();
            }
        }
    };

    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    return (
        <aside
            id={MESSAGE_HOLDER_ID}
            className="uk-modal is-message uk-open uk-display-block uk-text-center"
            role="region"
            onClick={closeAll}
        >
            <div className={classnames('uk-modal-dialog', 'uk-padding-remove', props.classNames)}>
                {!isNil(props.onClose) && (
                    <button className="mg-close" onClick={props.onClose} type="button" ref={closeRef}>
                        <FontAwesomeIcon icon="times" />
                    </button>
                )}
                {props.children}
            </div>
        </aside>
    );
});
/* eslint-enable jsx-a11y/no-noninteractive-element-interactions */

MessageHolder.propTypes = {
    children: node.isRequired,
    onClose: func,
    classNames: string,
    preventCloseButtonFocus: bool,
};

MessageHolder.defaultProps = {
    preventCloseButtonFocus: false,
};

export default MessageHolder;
