export const dashboardFilterKeys = {
    ANCHOR_FILTER: 'anchorFilter',
    HREF_FILTER: 'hrefFilter',
    MAX_TOP_RANK: 'maxTopRank',
    MAX_CITATION_FLOW: 'maxCitationFlow',
    MAX_EXTERNAL_LINKS: 'maxExternalLinks',
    MAX_LINK_STRENGTH: 'maxLinkStrength',
    MAX_TRUST_FLOW: 'maxTrustFlow',
    MIN_TOP_RANK: 'minTopRank',
    MIN_CITATION_FLOW: 'minCitationFlow',
    MIN_EXTERNAL_LINKS: 'minExternalLinks',
    MIN_LINK_STRENGTH: 'minLinkStrength',
    MIN_TRUST_FLOW: 'minTrustFlow',
    URL_FILTER: 'urlFilter',
};

export const exportFilterKeys = {
    LINKS_PER_DOMAIN: 'linksPerDomain',
    TARGET_ANCHOR_TEXT: 'targetAnchorText',
    TARGET_HREF_TEXT: 'targetHrefText',
    MIN_TOP_RANK: 'minTopRank',
    MAX_TOP_RANK: 'maxTopRank',
    MIN_CITATION_FLOW: 'minCitationFlow',
    MAX_CITATION_FLOW: 'maxCitationFlow',
    MIN_TRUST_FLOW: 'minTrustFlow',
    MAX_TRUST_FLOW: 'maxTrustFlow',
    MIN_LINK_STRENGTH: 'minLinkStrength',
    MAX_LINK_STRENGTH: 'maxLinkStrength',
    NO_FOLLOW: 'noFollow',
    LIVE_LINKS: 'liveLinks',
};
