import React, { useEffect, useState, useRef } from 'react';
import { arrayOf, bool, func } from 'prop-types';
import ReactList from 'react-list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'ramda';
import queryString from 'query-string';
import { useFocusOnMount, Input, withVisibilityLogic, ListNavigation } from 'mangools-react-components';
import classnames from 'classnames';
import { WHITELIST_DATA_ATTRIBUTE } from 'mangools-commons/lib/constants/Shortcuts';

import PanelFavoriteItemPreloader from 'components/panels/PanelFavoriteItemPreloader';
import FavoriteItem from 'components/panels/favoritesPanel/FavoriteItem';

import RoutePaths from 'constants/RoutePaths';
import FavoriteType from 'types/FavoriteType';

import emptyImg from 'images/sad.svg';

import shortcuts, { ARROW_DOWN, ARROW_UP, ENTER } from 'constants/Shortcuts';

const FavoritesPanel = React.memo(props => {
    const listRef = useRef(null);
    const filterInputRef = useRef(null);
    const closeRef = useFocusOnMount();
    const [preselectedListId, setPreselectedListId] = useState(null);
    const [search, setSearch] = useState('');

    useEffect(() => {
        props.onOpen();
    }, []);

    useEffect(() => {
        if (!isEmpty(props.data)) {
            setPreselectedListId(props.data[0].id);
        }
    }, [props.data]);

    useEffect(() => {
        setPreselectedListId(null);
    }, [search]);

    const getLink = listId => {
        const query = queryString.stringify({ list_id: listId });
        return `${RoutePaths.DASHBOARD}?${query}`;
    };

    const handleArrowDown = ([favoriteItem, index]) => {
        setPreselectedListId(favoriteItem.id);
        listRef.current.scrollAround(index + 1);
    };

    const handleArrowUp = ([favoriteItem, index]) => {
        setPreselectedListId(favoriteItem.id);
        listRef.current.scrollAround(index - 1);
    };

    const handleEnter = () => {
        const { requestNavigation, onClose } = props;

        requestNavigation(RoutePaths.DASHBOARD, { list_id: preselectedListId });
        onClose();
    };

    const handleFilterChange = e => setSearch(e.target.value);

    const handleFilterClear = () => {
        filterInputRef.current.blur();
        setSearch('');
    };

    /* eslint-disable max-len */
    const renderEmptyListMessage = () => (
        <div className="bg-fff uk-text-center mg-padding-45-0 mg-padding-0-60 uk-height-1-1 uk-flex uk-flex-middle uk-flex-center">
            <div>
                <div className="mg-margin-b-15">
                    <img src={emptyImg} alt="Oh no" width="120" />
                </div>
                <h3 className="font-24 uk-text-bold">No favorite backlinks?</h3>
                <p className="mg-margin-b-10">
                    Favorite backlinks by clicking on the&nbsp; &quot;star&quot; icon next to the link to save them for
                    future use.
                </p>
                <button className="mg-btn is-link is-small" onClick={props.onClose} type="button">
                    Close this panel
                </button>
            </div>
        </div>
    );
    /* eslint-enable max-len */

    const renderItems = (items, ref) => <div ref={ref}>{items}</div>;

    const renderBody = () => {
        if (props.fetching) {
            return (
                <div style={{ cursor: 'progress' }}>
                    <PanelFavoriteItemPreloader />
                    <PanelFavoriteItemPreloader />
                    <PanelFavoriteItemPreloader />
                    <PanelFavoriteItemPreloader />
                    <PanelFavoriteItemPreloader />
                </div>
            );
        } else if (!isEmpty(props.data)) {
            const searchFormatted = search.trim().toLowerCase();
            const data = props.data.filter(({ domain }) => domain.includes(searchFormatted));

            return (
                <ListNavigation
                    data={data}
                    selectedItemId={preselectedListId}
                    onArrowDownShortcut={handleArrowDown}
                    onArrowUpShortcut={handleArrowUp}
                    onEnterShortcut={handleEnter}
                    shortcutKeys={[ARROW_DOWN, ARROW_UP, ENTER]}
                    shortcuts={shortcuts}
                >
                    <ReactList
                        ref={listRef}
                        itemRenderer={(index, _key) => {
                            const item = data[index];

                            return (
                                <FavoriteItem
                                    backlinkCount={item.linkIds.length}
                                    createdAt={item.createdAt}
                                    domain={item.domain}
                                    id={item.id}
                                    key={item.id}
                                    onClose={props.onClose}
                                    onDeleteFavorite={props.onDeleteFavorite}
                                    openLink={getLink(item.id)}
                                    isPreselected={item.id === preselectedListId}
                                />
                            );
                        }}
                        itemsRenderer={renderItems}
                        length={data.length}
                        pageSize={15}
                        type="simple"
                        updateWhenDataChange={data}
                    />
                </ListNavigation>
            );
        } else {
            return renderEmptyListMessage();
        }
    };

    return (
        <div className="mg-panel">
            <div className="mg-panel-title mg-padding-30 mg-border-b">
                <h1 className="font-24 uk-margin-remove">
                    <FontAwesomeIcon
                        icon={['fas', 'star']}
                        aria-hidden="true"
                        className="color-orange mg-margin-r-10"
                    />
                    <strong>Favorites</strong>
                </h1>
                <button className="mg-close" onClick={props.onClose} type="button" ref={closeRef}>
                    <FontAwesomeIcon icon="times" />
                </button>
            </div>
            <div className="mg-panel-content uk-flex uk-flex-column">
                <div>
                    {/* eslint-disable-next-line max-len */}
                    <div className="bg-lightgrey mg-padding-0-30 mg-padding-5-0 uk-flex uk-flex-middle uk-position-relative">
                        <Input
                            autoFocus
                            className="mg-input is-void is-search in-dropdown"
                            debounceMs={50}
                            onChange={handleFilterChange}
                            placeholder="Search for a list..."
                            inputRef={filterInputRef}
                            value={search}
                            onClear={handleFilterClear}
                            spellCheck={false}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...{ [WHITELIST_DATA_ATTRIBUTE]: true }}
                        />
                        <button
                            className={
                                /* eslint-disable-next-line max-len */
                                classnames(
                                    'mg-icon-btn',
                                    'uk-position-top-right',
                                    'font-14',
                                    'uk-height-1-1',
                                    'mg-margin-r-15',
                                    {
                                        'uk-hidden': search.length === 0,
                                    },
                                )
                            }
                            onClick={handleFilterClear}
                            type="button"
                            style={{ zIndex: 2, width: '36px' }}
                        >
                            <FontAwesomeIcon icon="times" aria-label="Clear" />
                        </button>
                    </div>
                </div>
                <div className="uk-overflow-container uk-flex-item-auto mg-padding-30">{renderBody()}</div>
            </div>
        </div>
    );
});

FavoritesPanel.propTypes = {
    data: arrayOf(FavoriteType).isRequired,
    fetching: bool.isRequired,
    onClose: func.isRequired,
    onDeleteFavorite: func.isRequired,
    onOpen: func.isRequired,
    requestNavigation: func.isRequired,
};

export default withVisibilityLogic(FavoritesPanel);
