import update from 'immutability-helper';
import { isEmpty, omit, isNil, startsWith, includes } from 'ramda';

import { exportFilterKeys as filterKeys } from 'constants/FilterKeys';
import UrlTypes from 'constants/UrlTypes';
import { MAX_AVAILABLE_BACKLINK_COUNT, MIN_AVAILABLE_BACKLINK_EXPORT_COUNT } from '../constants/Other';

class BacklinkExportService {
    static predictExportType(url, { suggestedType, allIds, byIds }, cloneType = null) {
        if (!isNil(cloneType)) {
            const suggestion = allIds.find(
                suggestionId => byIds[suggestionId].type === cloneType && includes(url, byIds[suggestionId].url),
            );
            if (!isNil(suggestion)) {
                return suggestion;
            }
        } else if (!isEmpty(allIds) > 0) {
            if (suggestedType === UrlTypes.EXACT_URL && startsWith('https', url)) {
                const suggestion = allIds.find(
                    suggestionId =>
                        byIds[suggestionId].type === UrlTypes.EXACT_URL && startsWith('https', byIds[suggestionId].url),
                );

                if (!isNil(suggestion)) {
                    return suggestion;
                }
            } else if (suggestedType === UrlTypes.EXACT_URL && startsWith('http', url)) {
                const suggestion = allIds.find(
                    suggestionId =>
                        byIds[suggestionId].type === UrlTypes.EXACT_URL && startsWith('http', byIds[suggestionId].url),
                );

                if (!isNil(suggestion)) {
                    return suggestion;
                }
            } else {
                const suggestion = allIds.find(suggestionId => byIds[suggestionId].type === suggestedType);

                if (!isNil(suggestion)) {
                    return suggestion;
                }
            }
        }

        return null;
    }

    static calculateLimit(exportCost, roundedMaxLimit) {
        if (exportCost === MIN_AVAILABLE_BACKLINK_EXPORT_COUNT) {
            return MIN_AVAILABLE_BACKLINK_EXPORT_COUNT;
        } else if (roundedMaxLimit <= MAX_AVAILABLE_BACKLINK_COUNT / 2) {
            return roundedMaxLimit;
        } else {
            return Math.floor(roundedMaxLimit / 2);
        }
    }

    static getReadyInValue(cost) {
        if (cost <= 1000) {
            return '5 minutes';
        } else {
            return '30 minutes';
        }
    }

    static formatLimit(limitMax, roundedLimitMax, limit) {
        if (limit === roundedLimitMax) {
            return limitMax;
        } else if (limit < MIN_AVAILABLE_BACKLINK_EXPORT_COUNT) {
            return MIN_AVAILABLE_BACKLINK_EXPORT_COUNT;
        } else {
            return limit;
        }
    }

    static formatFilterSettings(filter) {
        if (!filter.active) {
            return {};
        } else {
            /* eslint-disable no-param-reassign */
            return update(filter.settings, settings => {
                if (settings[filterKeys.TARGET_ANCHOR_TEXT].length === 0) {
                    settings = omit([filterKeys.TARGET_ANCHOR_TEXT], settings);
                }

                if (settings[filterKeys.TARGET_HREF_TEXT].length === 0) {
                    settings = omit([filterKeys.TARGET_HREF_TEXT], settings);
                }

                if (settings[filterKeys.MIN_TOP_RANK] === 0) {
                    settings = omit([filterKeys.MIN_TOP_RANK], settings);
                }

                if (isNil(settings[filterKeys.MAX_TOP_RANK])) {
                    settings = omit([filterKeys.MAX_TOP_RANK], settings);
                }

                if (settings[filterKeys.MIN_CITATION_FLOW] === 0) {
                    settings = omit([filterKeys.MIN_CITATION_FLOW], settings);
                }

                if (isNil(settings[filterKeys.MAX_CITATION_FLOW])) {
                    settings = omit([filterKeys.MAX_CITATION_FLOW], settings);
                }

                if (settings[filterKeys.MIN_TRUST_FLOW] === 0) {
                    settings = omit([filterKeys.MIN_TRUST_FLOW], settings);
                }

                if (isNil(settings[filterKeys.MAX_TRUST_FLOW])) {
                    settings = omit([filterKeys.MAX_TRUST_FLOW], settings);
                }

                if (settings[filterKeys.MIN_LINK_STRENGTH] === 0) {
                    settings = omit([filterKeys.MIN_LINK_STRENGTH], settings);
                }

                if (isNil(settings[filterKeys.MAX_LINK_STRENGTH])) {
                    settings = omit([filterKeys.MAX_LINK_STRENGTH], settings);
                }

                return settings;
            });
        }
        /* eslint-enable no-param-reassign */
    }
}

export default BacklinkExportService;
