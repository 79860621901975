import { createSelector } from 'reselect';
import { concat, forEach, reduce, sum, isNil, uniqBy, prop } from 'ramda';
import update from 'immutability-helper';

import { urlParamSelector } from 'selectors/paramsSelectors';

import { limitsFetchedSelector, newUserMessageVisibilitySelector } from 'selectors/userSelectors';

import {
    currentResultIdSelector,
    filterQuickSettingsSelector,
    filterSettingsSelector,
    sortingSettingsSelector,
    topLevelAnnouncementMessageVisibilitySelector,
    filterAdvancedActiveSelector,
} from 'selectors/commonSelectors';

import ResultFilterService from 'services/ResultFilterService';
import ResultSorterService from 'services/ResultSorterService';

import { ANNOUNCEMENT_EMPTY_MESSAGE } from 'reducers/data/announcementsReducer';
import { PRELOADER_RESULTS } from 'constants/Other';
import UrlTypes from 'constants/UrlTypes';

// Results (links) data
const resultsDataMetaUrlTypeRawSelector = state => state.data.results.meta.urlType;
const resultsDataSelector = state => state.data.results.data;
export const resultDataErrorSelector = state => state.data.results.error;

export const allResultsDataCountSelector = state => state.data.results.data.length;
export const resultsFetchingMoreDisabledSelector = state => state.data.results.fetchingMoreDisabled;
export const resultsFetchingSelector = state => state.data.results.fetching;
export const resultsMoreFetchingSelector = state => state.data.results.fetchingMore;

const uniqueResultsDataSelector = createSelector([resultsDataSelector], results => uniqBy(prop('id'), results));

export const resultsDataCountSelector = createSelector([uniqueResultsDataSelector], results => results.length);

export const filteredAndSortedResultsDataSelector = createSelector(
    [
        filterQuickSettingsSelector,
        filterSettingsSelector,
        uniqueResultsDataSelector,
        sortingSettingsSelector,
        filterAdvancedActiveSelector,
    ],
    (filterQuickSettings, filterSettings, resultsData, sortingSettings, advancedFilterActive) => {
        const filteredData = ResultFilterService.filter({
            data: resultsData,
            settings: {
                advanced: { ...filterSettings, active: advancedFilterActive },
                quick: filterQuickSettings,
            },
        });

        const sortedData = ResultSorterService.sort({
            data: filteredData,
            settings: sortingSettings,
        });

        return sortedData;
    },
);

export const filteredAndSortedResultsDataWithPreloaderResults = createSelector(
    [filteredAndSortedResultsDataSelector, resultsFetchingSelector, resultsMoreFetchingSelector],
    (filteredResults, fetching, fetchingMore) => {
        if (fetching === true) {
            return PRELOADER_RESULTS;
        } else if (fetchingMore === true) {
            return concat(filteredResults, PRELOADER_RESULTS);
        } else {
            return filteredResults;
        }
    },
);

export const filteredAndSortedResultsDataCountSelector = createSelector(
    [filteredAndSortedResultsDataSelector],
    filteredResults => filteredResults.length,
);

// NOTE: is calculating for every load more
// and in normal (non favorite list dashboard view) is not even visible
export const allResultsAvgCitationFlowSelector = createSelector([uniqueResultsDataSelector], results => {
    const withCitationFlow = results.filter(item => item.citationFlow > 0);
    const citationFlows = withCitationFlow.map(item => item.citationFlow);

    if (withCitationFlow.length > 0) {
        return sum(citationFlows) / withCitationFlow.length;
    } else {
        return 0;
    }
});

// NOTE: is calculating for every load more
// and in normal (non favorite list dashboard view) is not even visible
export const allResultsAvgTrustFlowSelector = createSelector([uniqueResultsDataSelector], results => {
    const withTrustFlow = results.filter(item => item.trustFlow > 0);
    const trustFlows = withTrustFlow.map(item => item.trustFlow);

    if (withTrustFlow.length > 0) {
        return sum(trustFlows) / withTrustFlow.length;
    } else {
        return 0;
    }
});

// NOTE: is calculating for every load more
// and in normal (non favorite list dashboard view) is not even visible
export const allResultsAvgLinkStrengthSelector = createSelector([uniqueResultsDataSelector], results => {
    const withLinkStrength = results.filter(item => item.linkStrength > 0);
    const linkStrengths = withLinkStrength.map(item => item.linkStrength);

    if (withLinkStrength.length > 0) {
        return sum(linkStrengths) / withLinkStrength.length;
    } else {
        return 0;
    }
});

// NOTE: is calculating for every load more
// and in normal (non favorite list dashboard view) is not even visible
export const allResultsDoFollowRatioSelector = createSelector([uniqueResultsDataSelector], results => {
    const withDfFlag = results.filter(item => item.flags.noFollow === false);
    const withDfFlagCount = withDfFlag.length;

    if (withDfFlagCount > 0) {
        const decimal = withDfFlagCount / results.length;
        return (decimal * 100).toFixed(2);
    } else {
        return '0.00';
    }
});

// NOTE: is calculating for every load more
// and in normal (non favorite list dashboard view) is not even visible
export const allResultsActiveRatioSelector = createSelector([uniqueResultsDataSelector], results => {
    const withActiveFlag = results.filter(item => item.flags.deleted === false);
    const withActiveFlagCount = withActiveFlag.length;

    if (withActiveFlagCount > 0) {
        const decimal = withActiveFlagCount / results.length;
        return (decimal * 100).toFixed(2);
    } else {
        return '0.00';
    }
});

// NOTE: Creates a memoized map of backlinks ids
// for easy O(1) access for backlink item based on id
export const resultsMapSelector = createSelector([uniqueResultsDataSelector], results =>
    reduce(
        (map, val) => {
            const resultId = val.id;

            map[resultId] = val; // eslint-disable-line no-param-reassign

            return map;
        },
        {},
        results,
    ),
);

export const currentResultSelector = createSelector(
    [resultsMapSelector, currentResultIdSelector],
    (resultMap, id) => resultMap[id],
);

export const filteredAndSortedResultSourceUrlsSelector = createSelector(
    [filteredAndSortedResultsDataSelector],
    filteredResults => filteredResults.map(result => result.sourceUrl),
);

export const resultsDataMetaUrlTypeSelector = createSelector(
    [urlParamSelector, resultsDataMetaUrlTypeRawSelector],
    (url, type) => {
        if (url.indexOf('*') > -1) {
            return UrlTypes.PATH_URL;
        } else {
            return type;
        }
    },
);

// export const resultPageCountSelector = createSelector(
//     [resultsDataMetaAvailableLinkCountSelector],
//     availableCount => Math.ceil(availableCount / BACKLINK_PAGE_SIZE)
//     // Substract one as our paging is 0 based
//     // return pageCount > 0 ? pageCount - 1 : pageCount;
// );

// URL metrics data
export const urlCitationFlowSelector = state => state.data.url.data.citationFlow;
export const urlExternalBackLinksSelector = state => state.data.url.data.externalBackLinks;
export const urlFetchingSelector = state => state.data.url.fetching;
export const urlRefDomainsSelector = state => state.data.url.data.refDomains;
export const urlRefIPsSelector = state => state.data.url.data.refIPs;
export const urlTrustFlowSelector = state => state.data.url.data.trustFlow;

// Panels

// Favorites
export const favoritesDataSelector = state => state.data.favorites.data;
export const favoriteCountSelector = state => state.data.favorites.data.length;
export const fetchingFavoritesDataSelector = state => state.data.favorites.fetching;

export const favoriteBacklinkIdsMapSelector = createSelector([favoritesDataSelector], favorites => {
    const result = {};

    reduce(
        (map, favorite) => {
            const { linkIds } = favorite;

            forEach(id => {
                map[id] = true; // eslint-disable-line no-param-reassign
            }, linkIds);

            return map;
        },
        result,
        favorites,
    );

    return result;
});

// History
export const historyDataSelector = state => {
    return state.data.history.data.map((entry, index) => {
        return update(entry, { $merge: { key: `${entry.id}-${index}` } });
    });
};
export const fetchingHistoryDataSelector = state => state.data.history.fetching;

// Announcements
const announcementsDataSelector = state => state.data.announcements.data;
const fetchingAnnouncementsDataSelector = state => state.data.announcements.fetching;

export const announcementsMessageDataSelector = createSelector([announcementsDataSelector], announcementsData => {
    if (isNil(announcementsData)) {
        return ANNOUNCEMENT_EMPTY_MESSAGE;
    } else {
        return announcementsData;
    }
});

export const announcementMesageVisibilitySelector = createSelector(
    [
        fetchingAnnouncementsDataSelector,
        announcementsMessageDataSelector,
        limitsFetchedSelector,
        topLevelAnnouncementMessageVisibilitySelector,
        newUserMessageVisibilitySelector,
    ],
    (fetchingAnnouncements, announcementsMessageData, fetchedLimits, announcementsVisible, newUserMessageVisible) => {
        if (
            fetchedLimits === true &&
            fetchingAnnouncements === false &&
            announcementsVisible === true &&
            newUserMessageVisible === false
        ) {
            if (announcementsMessageData.activeTo > Date.now() / 1000) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    },
);

export const backlinkExportSuggestionsDataSelector = state => {
    return {
        suggestionsByIds: state.data.backlinkExport.suggestionsData.byIds,
        suggestionsAllIds: state.data.backlinkExport.suggestionsData.allIds,
    };
};

export const backlinkExportSuggestionSuggestedTypeSelector = state =>
    state.data.backlinkExport.suggestionsData.suggestedType;

export const backlinkExportSuggestionsFetchingSelector = state => state.data.backlinkExport.suggestionsFetching;
export const backlinkExportsSelector = state => state.data.backlinkExport.data;
export const backlinkExportsFetchingSelector = state => state.data.backlinkExport.fetching;
