import React from 'react';
import { bool, func } from 'prop-types';
import MessageHolder from 'components/messages/MessageHolder';

function renderComandOrCtrl() {
    return navigator.platform.includes('Mac') ? '⌘' : 'Ctrl';
}

function ShortcutsMessage(props) {
    if (props.visible) {
        return (
            /* eslint-disable max-len */
            <MessageHolder onClose={props.onClose}>
                <div className="mg-modal-content uk-text-left">
                    <div className="mg-padding-30-0">
                        <h3 className="font-30 uk-text-bold uk-text-center uk-margin-remove">Keyboard shortcuts</h3>
                    </div>
                    <div className="uk-flex uk-flex-space-between uk-flex-middle">
                        <div>
                            <strong>Navigate table</strong>
                        </div>
                        <div className="font-14">
                            <span className="mg-input is-xsmall is-key">
                                &darr; / <strong>J</strong>
                            </span>
                            &nbsp;or&nbsp;
                            <span className="mg-input is-xsmall is-key">
                                &uarr; / <strong>K</strong>
                            </span>
                        </div>
                    </div>
                    <div className="mg-padding-15-0 mg-border-b mg-margin-b-15 font-14">
                        Press &quot;Up&quot; or &quot;Down&quot; arrow keys or J / K to navigate the backlinks table.
                    </div>

                    <div className="uk-flex uk-flex-space-between uk-flex-middle">
                        <div>
                            <strong>Open the website overview</strong>
                        </div>
                        <div className="font-14">
                            <strong className="mg-input is-xsmall is-key">Enter</strong>
                        </div>
                    </div>
                    <div className="mg-padding-15-0 mg-border-b mg-margin-b-15 font-14">
                        Press &quot;Enter&quot; while navigating the table to open the website preview for the
                        highlighted backlink.
                    </div>

                    <div className="uk-flex uk-flex-space-between uk-flex-middle">
                        <div>
                            <strong>Add / remove backlink to / from a favorite list.</strong>
                        </div>
                        <div className="font-14">
                            <strong className="mg-input is-xsmall is-key">F</strong>
                            &nbsp;or&nbsp;
                            <strong className="mg-input is-xsmall is-key">L</strong>
                        </div>
                    </div>
                    <div className="mg-padding-15-0 mg-border-b mg-margin-b-15 font-14">
                        Press &quot;L&quot; or &quot;F&quot; to add / remove the backlink to a favorite list.
                    </div>

                    <div className="uk-flex uk-flex-space-between uk-flex-middle">
                        <div>
                            <strong>Export backlinks</strong>
                        </div>
                        <div className="font-14">
                            <strong className="mg-input is-xsmall is-key">E</strong>
                        </div>
                    </div>
                    <div className="mg-padding-15-0 mg-border-b mg-margin-b-15 font-14">
                        Press &quot;E&quot; to export backlinks visible in a table to a .csv file.
                    </div>

                    <div className="uk-flex uk-flex-space-between uk-flex-middle">
                        <div>
                            <strong>Copy backlinks</strong>
                        </div>
                        <div className="font-14">
                            <strong className="mg-input is-xsmall is-key">{renderComandOrCtrl()}</strong>
                            &nbsp;+&nbsp;<strong className="mg-input is-xsmall is-key">C</strong>
                        </div>
                    </div>
                    <div className="mg-padding-15-0 mg-border-b mg-margin-b-15 font-14">
                        Use &quot;{renderComandOrCtrl()} + C&quot; to copy backlinks visible in a table to the
                        clipbaord.
                    </div>

                    <div className="uk-flex uk-flex-space-between uk-flex-middle">
                        <div>
                            <strong>Quick find (list detail / import)</strong>
                        </div>
                        <div className="font-14">
                            <strong className="mg-input is-xsmall is-key">{renderComandOrCtrl()}</strong>
                            &nbsp;+&nbsp;<strong className="mg-input is-xsmall is-key">F</strong>
                            &nbsp;or&nbsp;<strong className="mg-input is-xsmall is-key">/</strong>
                        </div>
                    </div>
                    <div className="mg-padding-15-0 mg-border-b mg-margin-b-15 font-14">
                        Press &quot;{renderComandOrCtrl()} + F&quot; or &quot;/&quot; to focus on the &quot;Find in
                        table&quot; input above the keywords table (Currently available in the keyword lists and
                        imported keywords).
                    </div>

                    <div className="uk-flex uk-flex-space-between uk-flex-middle">
                        <div>
                            <strong>Close a dropdown / modal</strong>
                        </div>
                        <div className="font-14">
                            <strong className="mg-input is-xsmall is-key">Esc</strong>
                        </div>
                    </div>
                    <div className="mg-padding-15-0 font-14">
                        Press &quot;Esc&quot; to close any dropdown or modal window.
                    </div>
                </div>
            </MessageHolder>
            /* eslint-enable max-len */
        );
    } else {
        return null;
    }
}

ShortcutsMessage.propTypes = {
    onClose: func.isRequired,
    visible: bool.isRequired,
};

export default ShortcutsMessage;
