import React from 'react';

function PanelFavoriteItemPreloader() {
    return (
        <div className="mg-card mg-padding-30 mg-margin-b-15">
            <div className="mg-preloader-block uk-width-6-10" />
        </div>
    );
}

PanelFavoriteItemPreloader.propTypes = {};

export default PanelFavoriteItemPreloader;
