import update from 'immutability-helper';

import FilterService from 'services/FilterService';

import ActionTypes from 'constants/ActionTypes';
import { exportFilterSettings } from 'constants/Filters';
import { exportFilterKeys } from 'constants/FilterKeys';

const initialState = {
    url: '',
    selectedSuggestionUrl: null,
    limit: 0,
    filter: {
        active: false,
        visibility: false,
        settings: FilterService.createInitialStoreState(exportFilterSettings),
    },
};

const createExportReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.DATA_NEW_BACKLINK_EXPORT_FETCHING: {
            return update(state, {
                $set: initialState,
            });
        }
        case ActionTypes.UI_CREATE_EXPORT_FILTER_ACTIVE_SET: {
            return update(state, {
                filter: {
                    active: { $set: payload },
                },
            });
        }
        case ActionTypes.UI_CREATE_EXPORT_FILTER_SETTINGS_SET: {
            return update(state, {
                filter: {
                    active: { $set: true },
                    visibility: { $set: false },
                    settings: { $set: update(initialState.filter.settings, { $merge: payload }) },
                },
            });
        }
        case ActionTypes.UI_CREATE_EXPORT_FILTER_VISIBILITY_TOGGLE: {
            return update(state, {
                filter: {
                    visibility: {
                        $apply: visibility => !visibility,
                    },
                },
            });
        }
        case ActionTypes.UI_CREATE_EXPORT_FILTER_RESET: {
            return update(state, {
                filter: {
                    settings: {
                        $set: update(initialState.filter.settings, {
                            $merge: {
                                [exportFilterKeys.LINKS_PER_DOMAIN]: 1,
                                [exportFilterKeys.LIVE_LINKS]: true,
                                [exportFilterKeys.NO_FOLLOW]: false,
                            },
                        }),
                    },
                    visibility: { $set: false },
                },
            });
        }
        case ActionTypes.UI_CREATE_EXPORT_URL_SET:
            return update(state, {
                url: { $set: payload },
            });
        case ActionTypes.UI_CREATE_EXPORT_SELECTED_SUGGESTION_URL_SET:
            return update(state, {
                selectedSuggestionUrl: { $set: payload },
            });
        case ActionTypes.UI_CREATE_EXPORT_LIMIT_SET:
            return update(state, {
                limit: { $set: payload },
            });
        case ActionTypes.UI_DASHBOARD_RESET: {
            return update(state, {
                $set: update(initialState, {
                    filter: {
                        settings: {
                            $merge: {
                                [exportFilterKeys.LINKS_PER_DOMAIN]: 1,
                                [exportFilterKeys.LIVE_LINKS]: true,
                                [exportFilterKeys.NO_FOLLOW]: false,
                            },
                        },
                    },
                }),
            });
        }
        default: {
            return state;
        }
    }
};

export default createExportReducer;
