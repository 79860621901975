import update from 'immutability-helper';
import { REHYDRATE } from 'redux-persist/constants';
import hasPath from 'ramda/src/hasPath';

import ActionTypes from 'constants/ActionTypes';
import SortingColumns from 'constants/SortingColumns';
import SortingTypes from 'constants/SortingTypes';
import DashboardDataTypes from 'constants/DashboardDataTypes';
import { MOBILE } from 'constants/WebsitePreviewSizes';

const initialState = {
    currentDataSource: {
        id: null,
        name: '',
        type: DashboardDataTypes.ALL_LINKS,
    },
    currentResultId: null,
    favoritingLinkId: null,
    sortingSettings: {
        column: SortingColumns.BACKLINK_URL,
        type: SortingTypes.DESC,
    },
    websitePreview: {
        size: MOBILE,
        visibility: true,
    },
};

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UI_DASHBOARD_CURRENT_RESULT_SET: {
            return update(state, {
                currentResultId: { $set: action.payload },
            });
        }
        case ActionTypes.DATA_RESULTS_FAVORITE_FLAG_SET_FETCHING: {
            return update(state, {
                favoritingLinkId: { $set: action.payload },
            });
        }
        case ActionTypes.DATA_RESULTS_FAVORITE_FLAG_SET_RECEIVED:
        case ActionTypes.DATA_RESULTS_FAVORITE_FLAG_SET_ERROR: {
            return update(state, {
                favoritingLinkId: { $set: initialState.favoritingLinkId },
            });
        }
        case ActionTypes.UI_DASHBOARD_SORTING_SETTINGS_SET: {
            return update(state, {
                sortingSettings: {
                    column: { $set: action.payload.column },
                    type: { $set: action.payload.type },
                },
            });
        }
        case ActionTypes.UI_DASHBOARD_CURRENT_DATA_SOURCE_SET: {
            return update(state, {
                currentDataSource: {
                    id: { $set: action.payload.id },
                    name: { $set: action.payload.name },
                    type: { $set: action.payload.type },
                },
            });
        }
        case ActionTypes.UI_DASHBOARD_WEBSITE_PREVIEW_SIZE_SET: {
            return update(state, {
                websitePreview: {
                    size: { $set: action.payload },
                },
            });
        }
        case ActionTypes.UI_DASHBOARD_WEBSITE_PREVIEW_VISIBILITY_SET: {
            return update(state, {
                websitePreview: {
                    visibility: { $set: action.payload },
                },
            });
        }
        case ActionTypes.UI_DASHBOARD_RESET: {
            return update(state, {
                currentDataSource: { $set: initialState.currentDataSource },
                currentResultId: { $set: initialState.currentResultId },
                favoritingLinkId: { $set: initialState.favoritingLinkId },
                sortingSettings: { $set: initialState.sortingSettings },
            });
        }
        case REHYDRATE: {
            if (hasPath(['ui', 'dashboard', 'websitePreview'], action.payload)) {
                const { websitePreview: savedWebsitePreview } = action.payload.ui.dashboard;

                return update(state, {
                    websitePreview: {
                        size: { $set: savedWebsitePreview.size || initialState.websitePreview.size },
                        visibility: {
                            // NOTE: Using hasPath because || is not safe here as `visibility`
                            // is boolean and `false` is accepted value.
                            $set: hasPath(['visibility'], savedWebsitePreview)
                                ? savedWebsitePreview.visibility
                                : initialState.websitePreview.visibility,
                        },
                    },
                });
            } else {
                return state;
            }
        }
        default: {
            return state;
        }
    }
};

export default dashboardReducer;
