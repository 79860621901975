import { eventChannel } from 'redux-saga';
import { delay, call, spawn, put, race, select, take, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';
import { isNil, isEmpty } from 'ramda';
import FileService from 'mangools-commons/lib/services/FileService';
import DownloaderService from 'mangools-commons/lib/services/DownloaderService';

import config from 'appConfig';

import AnnouncementsSource from 'sources/AnnouncementsSource';
import FavoriteListSource from 'sources/FavoriteListSource';
import HistorySource from 'sources/HistorySource';
import ResultExportService from 'services/ResultExportService';
import ResultSource from 'sources/ResultSource';
import UrlDataSource from 'sources/UrlDataSource';
import VersionSource from 'sources/VersionSource';
import BacklinkExportSource from 'sources/BacklinkExportSource';
import UnleashSource from 'sources/UnleashSource';

import {
    cancelledFavoriteListsDeleteAction,
    errorAnnouncementsAction,
    errorExportAction,
    errorFavoriteListsAction,
    errorFavoriteListsDeleteAction,
    errorHistoryAction,
    errorMoreResultsAction,
    errorResultsAction,
    errorSetFavoriteFlagForLinkAction,
    errorUrlDataAction,
    fetchingAnnouncementsAction,
    fetchingFavoriteListsAction,
    fetchingFavoriteListsDeleteAction,
    fetchingHistoryAction,
    fetchingMoreResultsAction,
    fetchingResultsAction,
    fetchingSetFavoriteFlagForLinkAction,
    fetchingUrlDataAction,
    receivedAnnouncementsAction,
    receivedExportAction,
    receivedFavoriteListResultsAction,
    receivedFavoriteListsAction,
    receivedFavoriteListsDeleteAction,
    receivedHistoryAction,
    receivedMoreResultsAction,
    receivedResultsAction,
    receivedSetFavoriteFlagForLinkAction,
    receivedUrlDataAction,
    skippedFavoriteListsAction,
    skippedHistoryAction,
    errorDeleteHistoryAction,
    receivedDeleteHistoryAction,
    receivedBacklinkExportSuggestionsAction,
    receivedBacklinkExportsAction,
    errorBacklinkExportSuggestionsAction,
    errorBacklinkExportsAction,
    receivedNewBacklinkExportAction,
    errorNewBacklinkExportAction,
    requestedBacklinkExportsAction,
    fetchingBacklinkExportsAction,
    fetchingNewBacklinkExportAction,
    resetBacklinkExportSuggestionsAction,
} from 'actions/dataActions';

import {
    setCurrentDashboardDataSource,
    setNewVersionNotificationShown,
    showAccessDeniedMessage,
    showDeleteConfirmationMessage,
    showFailureMessage,
    showNoConnectionMessage,
    showPricingMessage,
    showCreateExportSuccessMessage,
    showNotExistingDomainMessage,
    setCreateExportSelectedSuggestionUrl,
    setCreateExportBacklinkLimit,
    setCreateExportFilterActive,
    setCreateExportFilterSettings,
} from 'actions/uiActions';

import { requestedLimitsAction, setUnleashSessionAction } from 'actions/userActions';

import { requestedNavigationAction } from 'actions/routerActions';

import { accessTokenSelector } from 'selectors/userSelectors';

import {
    linksPerDomainParamSelector,
    pageParamSelector,
    sourceParamSelector,
    urlParamSelector,
} from 'selectors/paramsSelectors';

import { filteredAndSortedResultsDataSelector } from 'selectors/dataSelectors';

import {
    newVersionNotificationShownSelector,
    createExportFormDataSelector,
    createExportLimitRoundedMaxSelector,
    createExportLimitMaxSelector,
    createExportLimitSelector,
} from 'selectors/uiSelectors';

import { currentDataSourceIdSelector, currentDataSourceTypeSelector } from 'selectors/commonSelectors';

import { showInfoNotification } from 'sagas/uiSagas';

import { handleUncaught, logError, handleError } from 'sagas/errorSagas';
import Defaults from 'mangools-commons/lib/constants/Defaults';

import ErrorCodes, {
    INTERNAL_TIMEOUT_ERROR_PAYLOAD,
    INTERNAL_UNCAUGHT_ERROR_PAYLOAD,
} from 'mangools-commons/lib/constants/ErrorCodes';

import ActionTypes from 'constants/ActionTypes';
import Strings from 'constants/Strings';
import DashboardDataTypes, { getTypeFromSourceId } from 'constants/DashboardDataTypes';
import RoutePaths from 'constants/RoutePaths';
import DeleteResourceTypes from 'constants/DeleteResourceTypes';
import BacklinkExportService from 'services/BacklinkExportService';

import { initialState as backlinkExportReducerInitialState } from 'reducers/data/backlinkExportReducer';

import { exportFilterKeys } from 'constants/FilterKeys';
import { ErrorTypes } from '../constants/ErrorTypes';

const EXPORT_PREFIX = 'linkminer_';

const fetchResults = handleUncaught(
    function* fetchResults(action, retrying = false) {
        yield put(fetchingResultsAction());
        const accessToken = yield select(accessTokenSelector);

        const linksPerDomain = yield select(linksPerDomainParamSelector);
        const page = yield select(pageParamSelector);
        const source = yield select(sourceParamSelector);
        const url = yield select(urlParamSelector);

        // Set dashboard data type
        yield put(
            setCurrentDashboardDataSource({
                type: getTypeFromSourceId(source),
            }),
        );

        const { result, _timeout } = yield race({
            result: call(ResultSource.getData, { accessToken }, { linksPerDomain, page, source, url }),
            _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
        });

        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                const { meta, links } = payload;
                yield put(receivedResultsAction({ page, meta, links }));
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(errorResultsAction(payload));
                            yield put(showNoConnectionMessage());
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(fetchResults, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.LOCKED: {
                        // NOTE: we display the message in table body
                        yield put(errorResultsAction(payload));
                        break;
                    }
                    case ErrorCodes.FAILED_DEPENDENCY: {
                        if (retrying === true) {
                            yield put(errorResultsAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.majestic_general_error }));
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(fetchResults, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorResultsAction(payload));
                        yield put(showAccessDeniedMessage());
                        break;
                    }
                    case ErrorCodes.UNATHORIZED: {
                        yield put(errorResultsAction(payload));
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorResultsAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({ details: Strings.messages.failure.too_many_requests_error }),
                            );
                        } else if (payload.type === ErrorTypes.RATE_LIMIT) {
                            yield put(requestedLimitsAction());
                            yield put(showPricingMessage());
                        }

                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(errorResultsAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'FetchResultsSaga', payload);
                        } else {
                            yield call(fetchResults, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(errorResultsAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_results_error }));
                            yield call(logError, 'FetchResultsSaga', payload);
                        } else {
                            yield call(fetchResults, action, true);
                        }
                        break;
                    }
                }
            }
        } else {
            // Timeout
            yield put(errorResultsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_results_error }));
            yield call(logError, 'FetchResultsSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    },
    function* onError() {
        yield put(errorResultsAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.fetch_results_error }));
    },
);

const fetchFavoriteListResults = handleUncaught(
    function* fetchFavoriteListResults(action, retrying = false) {
        yield put(fetchingResultsAction());
        const accessToken = yield select(accessTokenSelector);
        const { listId } = action.payload;

        // Set dashboard data type
        yield put(
            setCurrentDashboardDataSource({
                id: null,
                name: '',
                type: DashboardDataTypes.FAVORITE_LIST,
            }),
        );

        const { result, _timeout } = yield race({
            result: call(FavoriteListSource.getDetail, { accessToken }, { listId }),
            _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
        });

        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                const { id, domain, links } = payload;

                // Set dashboard data type with list id and domain
                yield put(
                    setCurrentDashboardDataSource({
                        id,
                        name: domain,
                        type: DashboardDataTypes.FAVORITE_LIST,
                    }),
                );

                yield put(receivedFavoriteListResultsAction(links));
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(errorResultsAction(payload));
                            yield put(showNoConnectionMessage());
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(fetchFavoriteListResults, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorResultsAction(payload));
                        yield put(showAccessDeniedMessage());
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorResultsAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({ details: Strings.messages.failure.too_many_requests_error }),
                            );
                        } else if (payload.type === ErrorTypes.RATE_LIMIT) {
                            yield put(requestedLimitsAction());
                            yield put(showPricingMessage());
                        }

                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(errorResultsAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'FetchFavoriteListResults', payload);
                        } else {
                            yield call(fetchFavoriteListResults, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(errorResultsAction(payload));
                            yield put(
                                showFailureMessage({
                                    details: Strings.messages.failure.fetch_favorite_list_results_error,
                                }),
                            );
                            yield call(logError, 'FetchFavoriteListResults', payload);
                        } else {
                            yield call(fetchFavoriteListResults, action, true);
                        }
                        break;
                    }
                }
            }
        } else {
            // Timeout
            yield put(errorResultsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_favorite_list_results_error }));
            yield call(logError, 'FetchFavoriteListResults', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    },
    function* onError() {
        yield put(errorResultsAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.fetch_favorite_list_results_error }));
    },
);

const fetchMoreResults = handleUncaught(
    function* fetchMoreResults(action, retrying = false) {
        yield put(fetchingMoreResultsAction());
        const accessToken = yield select(accessTokenSelector);

        const linksPerDomain = yield select(linksPerDomainParamSelector);
        const page = yield select(pageParamSelector);
        const source = yield select(sourceParamSelector);
        const url = yield select(urlParamSelector);

        const { result, _timeout } = yield race({
            result: call(ResultSource.getData, { accessToken }, { linksPerDomain, page, source, url }),
            _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
        });

        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                const { links } = payload;
                yield put(receivedMoreResultsAction(links));
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(errorMoreResultsAction(payload));
                            yield put(showNoConnectionMessage());
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(fetchMoreResults, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorMoreResultsAction(payload));
                        yield put(showAccessDeniedMessage());
                        break;
                    }
                    case ErrorCodes.UNATHORIZED: {
                        yield put(errorMoreResultsAction(payload));
                        break;
                    }
                    case ErrorCodes.FAILED_DEPENDENCY: {
                        if (retrying === true) {
                            yield put(errorResultsAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.majestic_general_error }));
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(fetchMoreResults, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorMoreResultsAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({
                                    details: Strings.messages.failure.too_many_requests_error,
                                }),
                            );
                        } else if (payload.type === ErrorTypes.RATE_LIMIT) {
                            yield put(requestedLimitsAction());
                            yield put(showPricingMessage());
                        }

                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(errorMoreResultsAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'FetchMoreResultsSaga', payload);
                        } else {
                            yield call(fetchMoreResults, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(errorMoreResultsAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_results_error }));
                            yield call(logError, 'FetchMoreResultsSaga', payload);
                        } else {
                            yield call(fetchMoreResults, action, true);
                        }
                        break;
                    }
                }
            }
        } else {
            // Timeout
            yield put(errorMoreResultsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_results_error }));
            yield call(logError, 'FetchMoreResultsSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    },
    function* onError() {
        yield put(errorMoreResultsAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.fetch_results_error }));
    },
);

const fetchUrlData = handleUncaught(
    function* fetchUrlData(action, retrying = false) {
        yield put(fetchingUrlDataAction());
        const accessToken = yield select(accessTokenSelector);
        const url = yield select(urlParamSelector);

        const { result, _timeout } = yield race({
            result: call(UrlDataSource.getData, { accessToken }, { url }),
            _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
        });

        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                yield put(receivedUrlDataAction(payload));
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(errorUrlDataAction(payload));
                            yield put(showNoConnectionMessage());
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(fetchUrlData, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorUrlDataAction(payload));
                        yield put(showAccessDeniedMessage());
                        break;
                    }
                    case ErrorCodes.UNATHORIZED: {
                        yield put(errorUrlDataAction(payload));
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorUrlDataAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({ details: Strings.messages.failure.too_many_requests_error }),
                            );
                        } else if (payload.type === ErrorTypes.RATE_LIMIT) {
                            // NOTE: Dont showing pricing message on internal limit fuckup
                            // yield put(requestedLimitsAction());
                            // yield put(showPricingMessage());
                        }

                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(errorUrlDataAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'FetchUrlDataSaga', payload);
                        } else {
                            yield call(fetchUrlData, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(errorUrlDataAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_link_data_error }));
                            yield call(logError, 'FetchUrlDataSaga', payload);
                        } else {
                            yield call(fetchUrlData, action, true);
                        }
                        break;
                    }
                }
            }
        } else {
            // Timeout
            yield put(errorUrlDataAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_link_data_error }));
            yield call(logError, 'FetchUrlDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    },
    function* onError() {
        yield put(errorUrlDataAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.fetch_link_data_error }));
    },
);

const exportResults = handleUncaught(
    function* exportResults(action) {
        const { includeMetrics } = action.payload;

        const results = yield select(filteredAndSortedResultsDataSelector);
        const url = yield select(urlParamSelector);
        const fileName = `${EXPORT_PREFIX}${FileService.sanitizeStringForFilename(url)}`;

        const csv = yield call(ResultExportService.export, {
            includeMetrics,
            results,
        });

        const success = yield call(DownloaderService.downloadCSV, fileName, csv);

        if (success) {
            yield put(receivedExportAction({ fileName, csv }));
            yield call(showInfoNotification, 'Backlinks were successfully exported.');
        } else {
            yield put(showFailureMessage({ details: Strings.messages.failure.download_error }));
            yield put(errorExportAction());
            yield call(logError, 'ExportResults|DownloaderService', {});
        }
    },
    function* onError() {
        yield put(showFailureMessage({ details: Strings.messages.failure.download_error }));
        yield put(errorExportAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

const fetchHistoryData = handleUncaught(
    function* fetchHistoryData(action, retrying = false) {
        const accessToken = yield select(accessTokenSelector);

        if (!isNil(accessToken)) {
            yield put(fetchingHistoryAction());

            const { result, _timeout } = yield race({
                result: call(HistorySource.getData, accessToken),
                _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    yield put(receivedHistoryAction(payload));
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorHistoryAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(fetchHistoryData, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorHistoryAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorHistoryAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorHistoryAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'FetchHistoryDataSaga', payload);
                            } else {
                                yield call(fetchHistoryData, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorHistoryAction(payload));
                                yield put(
                                    showFailureMessage({ details: Strings.messages.failure.fetch_history_error }),
                                );
                                yield call(logError, 'FetchHistoryDataSaga', payload);
                            } else {
                                yield call(fetchHistoryData, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorHistoryAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield put(showFailureMessage({ details: Strings.messages.failure.fetch_history_error }));
                yield call(logError, 'FetchHistoryDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        } else {
            yield put(skippedHistoryAction());
        }
    },
    function* onError() {
        yield put(errorHistoryAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.fetch_history_error }));
    },
);

const deleteHistoryData = handleUncaught(function* deleteHistoryData(action, retrying = false) {
    const accessToken = yield select(accessTokenSelector);

    const { result, _timeout } = yield race({
        result: call(HistorySource.delete, { accessToken }),
        _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
    });

    if (!isNil(accessToken)) {
        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                yield put(receivedDeleteHistoryAction());

                yield call(showInfoNotification, 'History was successfully cleared.', { html: false });
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(errorDeleteHistoryAction(payload));
                            yield put(showNoConnectionMessage());
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(deleteHistoryData, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorDeleteHistoryAction(payload));
                        yield put(showAccessDeniedMessage());
                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(errorDeleteHistoryAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'DeleteHistorySaga', payload);
                        } else {
                            yield call(deleteHistoryData, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorDeleteHistoryAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({
                                    details: Strings.messages.failure.too_many_requests_error,
                                }),
                            );
                        }

                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(errorDeleteHistoryAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.delete_history }));
                            yield call(logError, 'DeleteHistorySaga', payload);
                        } else {
                            yield call(deleteHistoryData, action, true);
                        }
                        break;
                    }
                }
            }
        } else {
            yield put(errorDeleteHistoryAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield call(logError, 'DeleteHistorySaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    }
});

const fetchFavoriteListsData = handleUncaught(
    function* fetchFavoriteListsData(retrying = false) {
        const accessToken = yield select(accessTokenSelector);

        if (!isNil(accessToken)) {
            yield put(fetchingFavoriteListsAction());

            const { result, _timeout } = yield race({
                result: call(FavoriteListSource.getData, { accessToken }),
                _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    yield put(receivedFavoriteListsAction(payload));
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorFavoriteListsAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(fetchFavoriteListsData, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorFavoriteListsAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorFavoriteListsAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorFavoriteListsAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'FetchFavoriteListsDataSaga', payload);
                            } else {
                                yield call(fetchFavoriteListsData, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorFavoriteListsAction(payload));
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.fetch_favorite_lists_error,
                                    }),
                                );
                                yield call(logError, 'FetchFavoriteListsDataSaga', payload);
                            } else {
                                yield call(fetchFavoriteListsData, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorFavoriteListsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield call(logError, 'FetchFavoriteListsDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        } else {
            yield put(skippedFavoriteListsAction());
        }
    },
    function* onError() {
        yield put(errorFavoriteListsAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

const setFavoriteFlag = handleUncaught(
    function* setFavoriteFlag(action, retrying = false) {
        const { linkId, favorite } = action.payload;
        const accessToken = yield select(accessTokenSelector);

        if (!isNil(accessToken)) {
            yield put(fetchingSetFavoriteFlagForLinkAction(linkId));

            const { result, _timeout } = yield race({
                result: call(FavoriteListSource.setFavorite, { accessToken }, { linkId, favorite }),
                _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    const dataSourceType = yield select(currentDataSourceTypeSelector);

                    yield put(
                        receivedSetFavoriteFlagForLinkAction({
                            meta: {
                                dataSourceType,
                                linkId,
                                newFavoriteFlag: favorite,
                            },
                            favoriteList: payload,
                        }),
                    );

                    yield call(
                        showInfoNotification,
                        `Link has been successfully added to <strong>${payload.domain}</strong> list`,
                        { html: true },
                    );
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorSetFavoriteFlagForLinkAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(setFavoriteFlag, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorSetFavoriteFlagForLinkAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.UNPROCESSABLE_ENTITY: {
                            yield put(errorSetFavoriteFlagForLinkAction(payload));
                            yield put(
                                showFailureMessage({
                                    details: Strings.messages.failure.set_favorite_flag_limit_error,
                                }),
                            );
                            yield call(logError, 'SetFavoriteFlagSaga', payload);
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorSetFavoriteFlagForLinkAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorSetFavoriteFlagForLinkAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'SetFavoriteFlagSaga', payload);
                            } else {
                                yield call(setFavoriteFlag, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorSetFavoriteFlagForLinkAction(payload));
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.set_favorite_flag_error,
                                    }),
                                );
                                yield call(logError, 'SetFavoriteFlagSaga', payload);
                            } else {
                                yield call(setFavoriteFlag, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorSetFavoriteFlagForLinkAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield put(showFailureMessage({ details: Strings.messages.failure.set_favorite_flag_error }));
                yield call(logError, 'SetFavoriteFlagSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        }
    },
    function* onError() {
        yield put(errorSetFavoriteFlagForLinkAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.set_favorite_flag_error }));
    },
);

const deleteFavoriteList = handleUncaught(
    function* deleteFavoriteList(action, retrying = false) {
        const { listId, domain } = action.payload;
        const accessToken = yield select(accessTokenSelector);

        // Confirmation message helper variables
        let _cancel = null; // eslint-disable-line no-underscore-dangle
        let _closeAll = null; // eslint-disable-line no-underscore-dangle
        let confirm = null;

        if (retrying === false) {
            // Show confirmation message and only proceed if is confirmed
            yield put(
                showDeleteConfirmationMessage({
                    resourceName: domain,
                    resourceType: DeleteResourceTypes.FAVORITE_LIST,
                }),
            );

            ({ _cancel, _closeAll, confirm } = yield race({
                _cancel: take(ActionTypes.UI_MESSAGES_DELETE_CONFIRMATION_CLOSE),
                _closeAll: take(ActionTypes.UI_ALL_CLOSE),
                confirm: take(ActionTypes.UI_MESSAGES_DELETE_CONFIRMATION_CONFIRM),
            }));
        }

        if ((!isNil(confirm) || retrying === true) && !isNil(accessToken)) {
            yield put(fetchingFavoriteListsDeleteAction());

            const { result, _timeout } = yield race({
                result: call(FavoriteListSource.delete, { accessToken }, { listId }),
                _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    const dataSourceType = yield select(currentDataSourceTypeSelector);

                    if (dataSourceType === DashboardDataTypes.FAVORITE_LIST) {
                        const dataSourceId = yield select(currentDataSourceIdSelector);

                        if (dataSourceId === listId) {
                            // Deleting currently opened favorite list, redirect to root
                            yield put(requestedNavigationAction(RoutePaths.ROOT, {}));
                        }
                    }

                    yield put(receivedFavoriteListsDeleteAction(listId));
                    yield call(
                        showInfoNotification,
                        `Favorite list <strong>${domain}</strong> was successfully deleted.`,
                        { html: true },
                    );
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorFavoriteListsDeleteAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(deleteFavoriteList, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorFavoriteListsDeleteAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorFavoriteListsDeleteAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorFavoriteListsDeleteAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'DeleteFavoriteListSaga', payload);
                            } else {
                                yield call(deleteFavoriteList, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorFavoriteListsDeleteAction(payload));
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.delete_favorite_list_error,
                                    }),
                                );
                                yield call(logError, 'DeleteFavoriteListSaga', payload);
                            } else {
                                yield call(deleteFavoriteList, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorFavoriteListsDeleteAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield put(showFailureMessage({ details: Strings.messages.failure.delete_favorite_list_error }));
                yield call(logError, 'DeleteFavoriteListSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        } else {
            yield put(cancelledFavoriteListsDeleteAction(listId));
        }
    },
    function* onError() {
        yield put(errorFavoriteListsDeleteAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.delete_favorite_list_error }));
    },
);

const fetchAnnouncements = handleUncaught(
    function* fetchAnnouncements(retrying = false) {
        yield put(fetchingAnnouncementsAction());

        const { result, _timeout } = yield race({
            result: call(AnnouncementsSource.getData),
            _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
        });

        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                yield put(receivedAnnouncementsAction(payload));
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(errorAnnouncementsAction(payload));
                            yield put(showNoConnectionMessage());
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(fetchAnnouncements, true);
                        }
                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorAnnouncementsAction(payload));
                        yield put(showAccessDeniedMessage());
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorAnnouncementsAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({
                                    details: Strings.messages.failure.too_many_requests_error,
                                }),
                            );
                        }

                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(errorAnnouncementsAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'FetchAnnouncementsSaga', payload);
                        } else {
                            yield call(fetchAnnouncements, true);
                        }
                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(errorAnnouncementsAction(payload));
                            yield put(
                                showFailureMessage({
                                    details: Strings.messages.failure.fetch_announcements_error,
                                }),
                            );
                            yield call(logError, 'FetchAnnouncementsSaga', payload);
                        } else {
                            yield call(fetchAnnouncements, true);
                        }
                        break;
                    }
                }
            }
        } else {
            yield put(errorAnnouncementsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield call(logError, 'FetchAnnouncementsSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    },
    function* onError() {
        yield put(errorAnnouncementsAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

const checkNewAppVersion = handleUncaught(function* checkNewAppVersion(action, retrying = false) {
    const { result, _timeout } = yield race({
        result: call(VersionSource.get),
        _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
    });

    if (!isNil(result)) {
        const { error, payload } = result;

        if (!error) {
            let newAppVersion;
            const currentAppVersion = config.APP_VERSION;

            if (config.production()) {
                newAppVersion = payload.linkminer;
            } else {
                newAppVersion = payload.linkminerBeta;
            }

            if (!isNil(newAppVersion)) {
                const newAppVersionParts = newAppVersion.split('.');
                const newAppVersionMajor = parseInt(newAppVersionParts[0], 10);
                // console.log('newAppVersionMajor', newAppVersionMajor);
                const newAppVersionMinor = parseInt(newAppVersionParts[1], 10);
                // console.log('newAppVersionMinor', newAppVersionMinor);

                const currentAppVersionParts = currentAppVersion.split('.');
                const currentAppVersionMajor = parseInt(currentAppVersionParts[0], 10);
                // console.log('currentAppVersionMajor', currentAppVersionMajor);
                const currentAppVersionMinor = parseInt(currentAppVersionParts[1], 10);
                // console.log('currentAppVersionMinor', currentAppVersionMinor);

                if (newAppVersionMajor > currentAppVersionMajor || newAppVersionMinor > currentAppVersionMinor) {
                    const notificationShown = yield select(newVersionNotificationShownSelector);

                    // Only show if not already shown during this session
                    if (notificationShown === false) {
                        // We have an older version of application.
                        // Show a special notification.
                        yield call(
                            showInfoNotification,
                            `
                                <h4 class="font-14">
                                    UPDATE AVAILABLE 🤩
                                </h4>

                                <p>
                                    Please, reload the application to get newest features and prevent possible glitches.
                                </p>

                                <br />

                                <button
                                    class="mg-btn is-xsmall is-orange is-gradient mg-margin-t-5"
                                    onclick="location.reload();"
                                    type="button"
                                >
                                    Reload now
                                </button>
                            `,
                            {
                                html: true,
                                timeout: 'none',
                            },
                        );

                        yield put(setNewVersionNotificationShown());
                    }
                }
            }
        } else {
            switch (payload.status) {
                case ErrorCodes.FETCH_ERROR: {
                    if (retrying !== true) {
                        // Wait for CONNECTION_RETRY_DELAY and try again
                        yield delay(Defaults.CONNECTION_RETRY_DELAY);
                        yield call(checkNewAppVersion, action, true);
                    }
                    break;
                }
                case ErrorCodes.SERVICE_UNAVAILABLE: {
                    if (retrying === true) {
                        yield call(logError, 'FetchAppVersionDataSaga', payload);
                    } else {
                        yield call(checkNewAppVersion, action, true);
                    }
                    break;
                }
                case ErrorCodes.INTERNAL_SERVER_ERROR:
                default: {
                    if (retrying === true) {
                        yield call(logError, 'FetchAppVersionDataSaga', payload);
                    } else {
                        yield call(checkNewAppVersion, action, true);
                    }
                    break;
                }
            }
        }
    } else {
        yield call(logError, 'FetchAppVersionDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
    }
});

function newAppVersionCheckIntervalChannel() {
    return eventChannel(emitter => {
        const intervalId = setInterval(() => {
            emitter({
                intervalId,
            });
        }, Defaults.APP_VERSION_CHECK_INTERVAL);

        return () => {
            clearInterval(intervalId);
        };
    });
}

const checkUnleashSession = handleUncaught(function* checkUnleashSession(action, retrying = false) {
    const { result, _timeout } = yield race({
        result: call(UnleashSource.get),
        _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
    });

    if (!isNil(result)) {
        const { error, payload } = result;

        if (!error) {
            yield put(setUnleashSessionAction({ unleashSession: payload.sessionId }));
        } else {
            switch (payload.status) {
                case ErrorCodes.FETCH_ERROR: {
                    if (retrying !== true) {
                        // Wait for CONNECTION_RETRY_DELAY and try again
                        yield delay(Defaults.CONNECTION_RETRY_DELAY);
                        yield call(checkUnleashSession, action, true);
                    }
                    break;
                }
                case ErrorCodes.SERVICE_UNAVAILABLE: {
                    if (retrying === true) {
                        yield call(logError, 'FetchUnleashSessionDataSaga', payload);
                    } else {
                        yield call(checkUnleashSession, action, true);
                    }
                    break;
                }
                case ErrorCodes.INTERNAL_SERVER_ERROR:
                default: {
                    if (retrying === true) {
                        yield call(logError, 'FetchUnleashSessionDataSaga', payload);
                    } else {
                        yield call(checkUnleashSession, action, true);
                    }
                    break;
                }
            }
        }
    } else {
        yield call(logError, 'FetchUnleashSessionDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
    }
});

export function* fetchAfterLoginData() {
    yield spawn(fetchFavoriteListsData);
    yield spawn(fetchAnnouncements);
    yield spawn(checkNewAppVersion);
    yield spawn(checkUnleashSession);
}

const fetchBacklinkExportSuggestions = handleUncaught(
    function* fetchBacklinkExportSuggestions(action, retrying = false) {
        const accessToken = yield select(accessTokenSelector);
        const { url, cloneType } = action.payload;

        if (!isNil(accessToken)) {
            const { result, _timeout } = yield race({
                result: call(BacklinkExportSource.suggestions, { accessToken }, url),
                _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    if (isEmpty(payload.allIds)) {
                        yield put(showNotExistingDomainMessage(url));
                    } else {
                        yield put(receivedBacklinkExportSuggestionsAction(payload));
                        yield put(setCreateExportFilterActive(true));
                        /*
                            NOTE:
                            Sets filter values. We do it here because we need different values
                            than initial state to display active filter badges
                         */
                        yield put(
                            setCreateExportFilterSettings({
                                [exportFilterKeys.LINKS_PER_DOMAIN]: 1,
                                [exportFilterKeys.LIVE_LINKS]: true,
                                [exportFilterKeys.NO_FOLLOW]: false,
                            }),
                        );

                        const exportType = BacklinkExportService.predictExportType(url, payload, cloneType);

                        if (!isNil(exportType)) {
                            yield put(setCreateExportSelectedSuggestionUrl(exportType));

                            const roundedMaxLimit = yield select(createExportLimitRoundedMaxSelector);

                            yield put(
                                setCreateExportBacklinkLimit(
                                    BacklinkExportService.calculateLimit(
                                        payload.byIds[exportType].cost,
                                        roundedMaxLimit,
                                    ),
                                ),
                            );
                        }
                    }
                } else {
                    const handler = handleError({
                        action,
                        error: payload,
                        sagaLogTitle: 'FetchBacklinkExportSuggestionsSaga',
                        retrying,
                        errorAction: errorBacklinkExportSuggestionsAction,
                        saga: fetchBacklinkExportSuggestions,
                        failureMessage: Strings.messages.failure.fetch_backlinks_suggestions_error,
                        handlers: {
                            [ErrorCodes.UNPROCESSABLE_ENTITY]: function* domainNotFound() {
                                yield put(showNotExistingDomainMessage(url));
                                yield put(
                                    receivedBacklinkExportSuggestionsAction(
                                        backlinkExportReducerInitialState.suggestionsData,
                                    ),
                                );
                            },
                            [ErrorCodes.TOO_MANY_REQUESTS]: function* limitReached() {
                                if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                    yield put(errorBacklinkExportSuggestionsAction(payload));
                                    yield put(
                                        showFailureMessage({
                                            details: Strings.messages.failure.too_many_requests_error,
                                        }),
                                    );
                                } else if (payload.type === ErrorTypes.RATE_LIMIT) {
                                    yield put(errorBacklinkExportSuggestionsAction(payload));
                                    yield put(
                                        showFailureMessage({
                                            details: Strings.messages.failure.majestic_out_of_credits,
                                        }),
                                    );
                                } else {
                                    yield put(errorBacklinkExportSuggestionsAction(payload));
                                    yield put(
                                        showFailureMessage({
                                            details: Strings.messages.failure.fetch_backlinks_suggestions_error,
                                        }),
                                    );
                                }
                            },
                        },
                    });

                    yield call(handler);
                }
            } else {
                yield put(errorBacklinkExportSuggestionsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield call(logError, 'FetchBacklinkExportSuggestionsSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        }
    },
    function* onError() {
        yield put(errorBacklinkExportSuggestionsAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

const fetchBacklinkExports = handleUncaught(
    function* fetchBacklinkExports(action, retrying = false) {
        const isInterval = action.payload;
        const accessToken = yield select(accessTokenSelector);

        if (!isNil(accessToken)) {
            if (!isInterval) {
                yield put(fetchingBacklinkExportsAction());
            }

            const { result, _timeout } = yield race({
                result: call(BacklinkExportSource.getData, { accessToken }),
                _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    yield put(receivedBacklinkExportsAction(payload));
                } else {
                    const handler = handleError({
                        action,
                        error: payload,
                        sagaLogTitle: 'fetchBacklinkExportsSaga',
                        retrying,
                        errorAction: errorBacklinkExportsAction,
                        saga: fetchBacklinkExports,
                        failureMessage: Strings.messages.failure.fetch_backlinks_error,
                    });

                    yield call(handler);
                }
            } else {
                yield put(errorBacklinkExportsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield call(logError, 'fetchBacklinkExportsSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        }
    },
    function* onError() {
        yield put(errorBacklinkExportsAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

const createBacklinkExport = handleUncaught(
    function* createBacklinkExport(action, retrying = false) {
        const { cost, url } = action.payload;

        const accessToken = yield select(accessTokenSelector);
        const { filter } = yield select(createExportFormDataSelector);
        const limitMax = yield select(createExportLimitMaxSelector);
        const roundedLimitMax = yield select(createExportLimitRoundedMaxSelector);
        const limit = yield select(createExportLimitSelector);

        const formattedLimit = BacklinkExportService.formatLimit(limitMax, roundedLimitMax, limit);

        yield put(resetBacklinkExportSuggestionsAction());

        if (!isNil(accessToken)) {
            yield put(fetchingNewBacklinkExportAction());

            const { result, _timeout } = yield race({
                result: call(
                    BacklinkExportSource.create,
                    { accessToken },
                    { url, limit: formattedLimit, filterSettings: filter },
                ),
                _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                const readyIn = BacklinkExportService.getReadyInValue(cost);

                if (!error) {
                    yield put(receivedNewBacklinkExportAction(payload));
                    yield put(showCreateExportSuccessMessage(readyIn));
                } else {
                    const handler = handleError({
                        action,
                        error: payload,
                        sagaLogTitle: 'CreateBacklinksExportsSaga',
                        retrying,
                        errorAction: errorNewBacklinkExportAction,
                        saga: createBacklinkExport,
                        failureMessage: Strings.messages.failure.create_backlinks_export,
                        handlers: {
                            [ErrorCodes.TOO_MANY_REQUESTS]: function* limitReached() {
                                if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                    yield put(errorNewBacklinkExportAction(payload));
                                    yield put(
                                        showFailureMessage({
                                            details: Strings.messages.failure.too_many_requests_error,
                                        }),
                                    );
                                } else if (payload.type === ErrorTypes.RATE_LIMIT) {
                                    yield put(errorNewBacklinkExportAction(payload));
                                    yield put(
                                        showFailureMessage({
                                            details: Strings.messages.failure.majestic_out_of_credits,
                                        }),
                                    );
                                } else {
                                    yield put(errorNewBacklinkExportAction(payload));
                                    yield put(
                                        showFailureMessage({
                                            details: Strings.messages.failure.create_backlinks_export,
                                        }),
                                    );
                                }
                            },
                        },
                    });

                    yield call(handler);
                }
            } else {
                yield put(errorNewBacklinkExportAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield call(logError, 'CreateBacklinksExportsSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        }
    },
    function* onError() {
        yield put(errorNewBacklinkExportAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

/**
 *
 * CHANNELS
 *
 */

function backlinkExportsIntervalChannel(interval) {
    return eventChannel(emitter => {
        const intervalRef = setInterval(() => {
            emitter(1);
        }, interval);

        return () => {
            clearInterval(intervalRef);
        };
    });
}

function* fetchBacklinkExportsPeriodic() {
    const channel = yield call(backlinkExportsIntervalChannel, 10000);

    // eslint-disable-next-line func-names
    yield takeLeading(channel, function* () {
        yield call(fetchBacklinkExports, requestedBacklinkExportsAction(true));
    });

    yield take(ActionTypes.DATA_BACKLINK_EXPORTS_REQUESTS_STOP);
    channel.close();
}

/**
 *
 * WATCHERS
 *
 */

function* watchBacklinkExportsByInterval() {
    yield takeLatest(ActionTypes.DATA_BACKLINK_EXPORTS_REQUESTS_START, fetchBacklinkExportsPeriodic);
}

function* watchNewAppVersionByInterval() {
    const channel = yield call(newAppVersionCheckIntervalChannel);
    yield takeEvery(channel, checkNewAppVersion);
}

function* watchResultsRequests() {
    yield takeLatest(ActionTypes.DATA_RESULTS_REQUESTED, fetchResults);
}

function* watchMoreResultsRequests() {
    yield takeLatest(ActionTypes.DATA_MORE_RESULTS_REQUESTED, fetchMoreResults);
}

function* watchFavoriteListDataRequests() {
    yield takeLatest(ActionTypes.DATA_FAVORITE_LISTS_REQUESTED, fetchFavoriteListsData);
}

function* watchFavoriteResultsRequests() {
    yield takeLatest(ActionTypes.DATA_RESULTS_FAVORITE_LIST_REQUESTED, fetchFavoriteListResults);
}

function* watchFavoriteFlagSetRequests() {
    yield takeLatest(ActionTypes.DATA_RESULTS_FAVORITE_FLAG_SET_REQUESTED, setFavoriteFlag);
}

function* watchFavoriteListsDeleteRequests() {
    yield takeLatest(ActionTypes.DATA_FAVORITE_LISTS_DELETE_REQUESTED, deleteFavoriteList);
}

function* watchUrlDataRequests() {
    yield takeLatest(ActionTypes.DATA_URL_DATA_REQUESTED, fetchUrlData);
}

function* watchHistoryRequests() {
    yield takeLatest(ActionTypes.DATA_HISTORY_REQUESTED, fetchHistoryData);
}

function* watchExportRequests() {
    yield takeLatest(ActionTypes.DATA_RESULTS_EXPORT_REQUESTED, exportResults);
}

function* watchHistoryDeleteRequests() {
    yield takeLatest(ActionTypes.DATA_HISTORY_DELETE_REQUESTED, deleteHistoryData);
}

function* watchBacklinksExportSuggestionsRequests() {
    yield takeLatest(ActionTypes.DATA_BACKLINK_EXPORT_SUGGESTIONS_REQUESTED, fetchBacklinkExportSuggestions);
}

function* watchBacklinksExportsRequests() {
    yield takeLatest(ActionTypes.DATA_BACKLINK_EXPORTS_REQUESTED, fetchBacklinkExports);
}

function* watchNewBacklinkExportRequests() {
    yield takeLatest(ActionTypes.DATA_NEW_BACKLINK_EXPORT_REQUESTED, createBacklinkExport);
}

export function* watchDataRequests() {
    yield spawn(watchResultsRequests);
    yield spawn(watchMoreResultsRequests);
    yield spawn(watchFavoriteResultsRequests);
    yield spawn(watchFavoriteFlagSetRequests);
    yield spawn(watchFavoriteListsDeleteRequests);
    yield spawn(watchFavoriteListDataRequests);
    yield spawn(watchUrlDataRequests);
    yield spawn(watchHistoryRequests);
    yield spawn(watchExportRequests);
    yield spawn(watchHistoryDeleteRequests);
    yield spawn(watchNewAppVersionByInterval);
    yield spawn(watchBacklinksExportSuggestionsRequests);
    yield spawn(watchBacklinksExportsRequests);
    yield spawn(watchNewBacklinkExportRequests);
    yield spawn(watchBacklinkExportsByInterval);
}
