import config from 'appConfig';
// import queryString from 'query-string';
import { parseIndex, parseDetail, parseItem } from 'sources/parsers/FavoriteListDataParser';
import ErrorCodes from 'mangools-commons/lib/constants/ErrorCodes';

// Constants
const ENDPOINT = '/favorites';
const FAV_LINKS_ENDPOINT = '/favorite-links';

/**
 * Data source class handling communication with API for keyword lists data.
 */
class FavoriteListSource {
    static getData({ accessToken }) {
        const requestURL = `${config.API_HOST}${config.API_PATH}${ENDPOINT}`;

        return fetch(requestURL, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'X-Access-Token': accessToken || '',
            },
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: parseIndex(data),
                        }))
                        .catch(ex => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: ex.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static getDetail({ accessToken }, { listId }) {
        const requestURL = `${config.API_HOST}${config.API_PATH}${ENDPOINT}/${listId}`;

        return fetch(requestURL, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'X-Access-Token': accessToken || '',
            },
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: parseDetail(data),
                        }))
                        .catch(ex => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: ex.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static delete({ accessToken }, { listId }) {
        const requestURL = `${config.API_HOST}${config.API_PATH}${ENDPOINT}/${listId}`;

        return fetch(requestURL, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'X-Access-Token': accessToken || '',
            },
        })
            .then(response => {
                if (response.ok) {
                    return {
                        error: false,
                        payload: null,
                    };
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static setFavorite({ accessToken }, { linkId, favorite }) {
        const requestURL = `${config.API_HOST}${config.API_PATH}${FAV_LINKS_ENDPOINT}/${linkId}`;

        return fetch(requestURL, {
            method: 'PATCH',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': accessToken || '',
            },
            body: JSON.stringify({
                favorite,
            }),
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: parseItem(data),
                        }))
                        .catch(ex => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: ex.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }
}

export default FavoriteListSource;
