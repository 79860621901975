export const COPY = 'COPY';
export const EXPORT = 'EXPORT';
export const ARROW_DOWN = 'ARROW_DOWN';
export const ARROW_UP = 'ARROW_UP';
export const ENTER = 'ENTER';
export const SHOW_SHORTCUTS_TOGGLE = 'SHOW_SHORTCUTS_TOGGLE';
export const CLOSE_ALL = 'CLOSE_ALL';
export const RESULT_FAVORITE = 'RESULT_FAVORITE';
export const SEARCH = 'SEARCH';

export default {
    [COPY]: ['command+c', ' ctrl+c'],
    [EXPORT]: 'e',
    [ARROW_DOWN]: ['shift+down', 'shift+j', 'down', 'j'],
    [ARROW_UP]: ['shift+up', 'shift+k', 'up', 'k'],
    [ENTER]: 'enter',
    [CLOSE_ALL]: 'esc',
    [SHOW_SHORTCUTS_TOGGLE]: 'shift+?',
    [RESULT_FAVORITE]: ['f', 'l'],
    [SEARCH]: ['command+f', 'ctrl+f', '/'],
};
