import { createSelector } from 'reselect';

import LinkRelTypes from 'constants/LinkRelTypes';
import LinkStatusTypes from 'constants/LinkStatusTypes';

// ==============================================
// Shared UI selectors
// ==============================================

// Messages
/* eslint-disable max-len */
export const topLevelAnnouncementMessageVisibilitySelector = state =>
    state.ui.messages.topLevel.announcement.visibility;
/* eslint-enable max-len */

// Dashboard
export const currentResultIdSelector = state => state.ui.dashboard.currentResultId;
export const sortingSettingsSelector = state => state.ui.dashboard.sortingSettings;
export const currentDataSourceIdSelector = state => state.ui.dashboard.currentDataSource.id;
export const currentDataSourceNameSelector = state => state.ui.dashboard.currentDataSource.name;
export const currentDataSourceTypeSelector = state => state.ui.dashboard.currentDataSource.type;

// Filter

// Advanced
export const filterAdvancedActiveSelector = state => state.ui.filter.advancedFilterActive;
export const filterSettingsSelector = state => state.ui.filter.settings;

// Quick
export const filterQuickLinkRelTypeSelector = state => state.ui.filter.quickSettings.linkRelType;
export const filterQuickLinkStatusTypeSelector = state => state.ui.filter.quickSettings.linkStatusType;
export const filterQuickSearchSelector = state => state.ui.filter.quickSettings.search;
export const filterQuickSettingsSelector = state => state.ui.filter.quickSettings;

export const filterQuickActiveSelector = createSelector(
    [filterQuickLinkRelTypeSelector, filterQuickLinkRelTypeSelector],
    (filterQuickLinkRelType, filterQuickLinkStatusType) =>
        filterQuickLinkRelType.value !== LinkRelTypes.ALL || filterQuickLinkStatusType.value !== LinkStatusTypes.ALL,
);

// ==============================================
// Shared router selectors
// ==============================================
export const currentQuerySelector = state => state.routing.locationBeforeTransitions.query;
export const currentRouteSelector = state => state.routing.locationBeforeTransitions.pathname;
