import React, { Component } from 'react';
import { arrayOf, bool, func } from 'prop-types';
import { connect } from 'react-redux';

import AppPanel from 'components/panels/AppPanel';
import HistoryPanel from 'components/panels/HistoryPanel';
import FavoritesPanel from 'components/panels/FavoritesPanel';
import FavoriteType from 'types/FavoriteType';
import HistoryType from 'types/HistoryType';
import RoutePaths from 'constants/RoutePaths';

import { closeAppPanel, closeFavoritesPanel, closeHistoryPanel } from 'actions/uiActions';

import {
    requestedFavoriteListsAction,
    requestedFavoriteListsDeleteAction,
    requestedHistoryAction,
    requestedDeleteHistoryAction,
} from 'actions/dataActions';

import {
    appPanelVisibilitySelector,
    favoritesPanelVisibilitySelector,
    historyPanelVisibilitySelector,
} from 'selectors/uiSelectors';

import {
    favoritesDataSelector,
    fetchingFavoritesDataSelector,
    fetchingHistoryDataSelector,
    historyDataSelector,
} from 'selectors/dataSelectors';

import { requestedNavigationAction } from 'actions/routerActions';

class PanelContainer extends Component {
    constructor() {
        super();
        this.handleHistoryClick = this.handleHistoryClick.bind(this);
    }

    handleHistoryClick({ linksPerDomain, source, url }) {
        this.props.onCloseHistoryPanel();
        this.props.requestNavigation(RoutePaths.DASHBOARD, {
            append: 0,
            linksPerDomain,
            page: 1,
            source,
            url,
        });
    }

    render() {
        return (
            <div>
                <AppPanel onClose={this.props.onCloseAppPanel} visible={this.props.appPanelVisible} />
                <FavoritesPanel
                    data={this.props.favoritesData}
                    fetching={this.props.fetchingFavoritesData}
                    onClose={this.props.onCloseFavoritesPanel}
                    onDeleteFavorite={this.props.onDeleteFavorite}
                    onOpen={this.props.requestFavoritesFetch}
                    visible={this.props.favoritesPanelVisible}
                    requestNavigation={this.props.requestNavigation}
                />
                <HistoryPanel
                    data={this.props.historyData}
                    fetching={this.props.fetchingHistoryData}
                    onClick={this.handleHistoryClick}
                    onClose={this.props.onCloseHistoryPanel}
                    onOpen={this.props.requestHistoryFetch}
                    onDeleteHistory={this.props.requestHistoryDelete}
                    visible={this.props.historyPanelVisible}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    appPanelVisible: appPanelVisibilitySelector(state),
    favoritesData: favoritesDataSelector(state),
    favoritesPanelVisible: favoritesPanelVisibilitySelector(state),
    fetchingFavoritesData: fetchingFavoritesDataSelector(state),
    fetchingHistoryData: fetchingHistoryDataSelector(state),
    historyData: historyDataSelector(state),
    historyPanelVisible: historyPanelVisibilitySelector(state),
});

const mapDispatchToProps = dispatch => ({
    onCloseAppPanel() {
        dispatch(closeAppPanel());
    },
    onCloseHistoryPanel() {
        dispatch(closeHistoryPanel());
    },
    onCloseFavoritesPanel() {
        dispatch(closeFavoritesPanel());
    },
    onDeleteFavorite({ listId, domain }) {
        dispatch(requestedFavoriteListsDeleteAction({ listId, domain }));
    },
    requestNavigation(route, params) {
        dispatch(requestedNavigationAction(route, params));
    },
    requestHistoryFetch() {
        dispatch(requestedHistoryAction());
    },
    requestFavoritesFetch() {
        dispatch(requestedFavoriteListsAction());
    },
    requestHistoryDelete() {
        dispatch(requestedDeleteHistoryAction());
    },
});

PanelContainer.propTypes = {
    appPanelVisible: bool.isRequired,
    favoritesData: arrayOf(FavoriteType).isRequired,
    favoritesPanelVisible: bool.isRequired,
    fetchingFavoritesData: bool.isRequired,
    fetchingHistoryData: bool.isRequired,
    historyData: arrayOf(HistoryType).isRequired,
    historyPanelVisible: bool.isRequired,
    onCloseAppPanel: func.isRequired,
    onCloseFavoritesPanel: func.isRequired,
    onCloseHistoryPanel: func.isRequired,
    onDeleteFavorite: func.isRequired,
    requestFavoritesFetch: func.isRequired,
    requestHistoryFetch: func.isRequired,
    requestNavigation: func.isRequired,
    requestHistoryDelete: func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(PanelContainer);
