import React from 'react';
import { Link } from 'react-router';
import classnames from 'classnames';
import { func, number, string } from 'prop-types';

import RouterService from 'services/RouterService';
import RoutePaths from 'constants/RoutePaths';

function MenuLeft(props) {
    return (
        <nav className="uk-flex uk-flex-middle">
            <ul className="mg-nav is-app uk-flex-inline">
                <li
                    className={classnames({
                        'uk-active': RouterService.isSame(props.currentRoute, RoutePaths.ROOT),
                    })}
                >
                    <Link to={RoutePaths.ROOT}>Search</Link>
                </li>
                <li
                    className={classnames({
                        'uk-active': RouterService.isSame(props.currentRoute, RoutePaths.EXPORT),
                    })}
                >
                    <Link to={RoutePaths.EXPORT}>Export</Link>
                </li>
                <li>
                    <button onClick={props.onShowFavoritesPanel} type="button">
                        Favorites
                        <sup className="mg-margin-l-5 font-10 uk-hidden-small">{props.favoriteCount}</sup>
                    </button>
                </li>
                <li>
                    <button onClick={props.onShowHistoryPanel} type="button">
                        History
                    </button>
                </li>
            </ul>
        </nav>
    );
}

MenuLeft.propTypes = {
    currentRoute: string.isRequired,
    favoriteCount: number.isRequired,
    onShowFavoritesPanel: func.isRequired,
    onShowHistoryPanel: func.isRequired,
};

export default MenuLeft;
