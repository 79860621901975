import ActionTypes from 'constants/ActionTypes';
import update from 'immutability-helper';
import concat from 'ramda/src/concat';

import UrlTypes from 'constants/UrlTypes';
import { BACKLINK_PAGE_SIZE } from 'constants/Other';

const initialState = {
    data: [],
    meta: {
        urlType: UrlTypes.EXACT_URL,
    },
    error: {
        status: null,
        text: null,
    },
    errorMore: {
        status: null,
        text: null,
    },
    fetching: false,
    fetchingMore: false,
    fetchingMoreDisabled: false,
};

const resultsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DATA_RESULTS_FETCHING: {
            return update(state, {
                fetching: { $set: true },
            });
        }
        case ActionTypes.DATA_RESULTS_RECEIVED: {
            return update(state, {
                fetching: { $set: false },
                fetchingMoreDisabled: { $set: action.payload.links.length < BACKLINK_PAGE_SIZE },
                error: { $set: initialState.error },
                data: { $set: action.payload.links },
                meta: {
                    availableCount: { $set: action.payload.meta.availableCount },
                    urlType: { $set: action.payload.meta.urlType },
                },
            });
        }
        case ActionTypes.DATA_RESULTS_FAVORITE_LIST_RECEIVED: {
            return update(state, {
                fetching: { $set: false },
                error: { $set: initialState.error },
                data: { $set: action.payload },
                meta: {
                    availableCount: { $set: initialState.meta.availableCount },
                    urlType: { $set: initialState.meta.urlType },
                },
            });
        }
        // case ActionTypes.DATA_RESULTS_FAVORITE_FLAG_SET_RECEIVED: {
        //     const unfavorited = action.payload.meta.newFavoriteFlag === false;
        //     const isFavoriteListSource = action.payload.meta.dataSourceType === DashboardDataTypes.FAVORITE_LIST;

        //     if (isFavoriteListSource && unfavorited) {
        //         return update(state, {
        //             data: { $apply: items => items.filter(item => item.id !== action.payload.meta.linkId) }
        //         });
        //     } else {
        //         return state;
        //     }
        // }
        case ActionTypes.DATA_RESULTS_ERROR: {
            return update(state, {
                data: { $set: initialState.data },
                fetching: { $set: false },
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
            });
        }
        case ActionTypes.DATA_MORE_RESULTS_FETCHING: {
            return update(state, {
                fetchingMore: { $set: true },
            });
        }
        case ActionTypes.DATA_MORE_RESULTS_RECEIVED: {
            return update(state, {
                data: { $apply: data => concat(data, action.payload) },
                errorMore: { $set: initialState.error },
                fetchingMore: { $set: false },
                fetchingMoreDisabled: { $set: action.payload.length < BACKLINK_PAGE_SIZE },
            });
        }
        case ActionTypes.DATA_MORE_RESULTS_ERROR: {
            return update(state, {
                fetchingMore: { $set: false },
                fetchingMoreDisabled: { $set: true },
                errorMore: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
            });
        }
        case ActionTypes.UI_DASHBOARD_RESET: {
            return update(state, {
                data: { $set: initialState.data },
            });
        }
        default: {
            return state;
        }
    }
};

export default resultsReducer;
