import React, { Suspense, lazy } from 'react';
import { Route, IndexRoute } from 'react-router';
import { node } from 'prop-types';

import AsyncLoaderErrorBoundaryContainer from 'components/AsyncLoaderErrorBoundaryContainer';
import FullAppPreloader from 'components/layout/FullAppPreloader';
import LayoutContainer from 'components/layout/LayoutContainer';

import RoutePaths from 'constants/RoutePaths';

const DashboardContainer = lazy(() => import('components/dashboard/DashboardContainer'));
const HomeContainer = lazy(() => import('components/home/HomeContainer'));
const NotFound = lazy(() => import('components/other/NotFound'));
const ExportPage = lazy(() => import('components/export/ExportPage'));

// Initialize async route handlers

/* eslint-disable react/jsx-props-no-spreading */
const LoaderWrapper = props => (
    <AsyncLoaderErrorBoundaryContainer>
        <Suspense fallback={<FullAppPreloader />}>{props.children}</Suspense>
    </AsyncLoaderErrorBoundaryContainer>
);

LoaderWrapper.propTypes = {
    children: node.isRequired,
};

function LoadDashboardContainer(props) {
    return (
        <LoaderWrapper>
            <DashboardContainer {...props} />
        </LoaderWrapper>
    );
}

function LoadHomeContainer(props) {
    return (
        <LoaderWrapper>
            <HomeContainer {...props} />
        </LoaderWrapper>
    );
}

function LoadNotFound(props) {
    return (
        <LoaderWrapper>
            <NotFound {...props} />
        </LoaderWrapper>
    );
}

function LoadExportContainer(props) {
    return (
        <LoaderWrapper>
            <ExportPage {...props} />
        </LoaderWrapper>
    );
}
/* eslint-enable react/jsx-props-no-spreading */

export default (
    <Route path={RoutePaths.ROOT} component={LayoutContainer}>
        <IndexRoute component={LoadHomeContainer} />
        <Route path={RoutePaths.DASHBOARD} component={LoadDashboardContainer} />
        <Route path={RoutePaths.EXPORT} component={LoadExportContainer} />
        <Route path="*" component={LoadNotFound} />
    </Route>
);
