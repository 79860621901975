import React from 'react';
import { bool, func } from 'prop-types';
import MessageHolder from 'components/messages/MessageHolder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';

import { signInRedirectSelector } from 'selectors/routingSelectors';

import Urls from 'mangools-commons/lib/constants/Urls';

function LoggedOutMessage(props) {
    const redirectRoute = useSelector(signInRedirectSelector);

    if (props.visible) {
        return (
            <MessageHolder onClose={props.onClose}>
                <div className="mg-modal-header is-info">
                    <FontAwesomeIcon icon="sign-out-alt" />
                </div>
                <div className="mg-modal-content">
                    <h3 className="font-30 uk-text-bold">You have been logged out</h3>
                    <p>
                        You have reached&nbsp;
                        <strong>maximum number of simultaneous logins</strong> for your plan. If you need more
                        concurrent logins, <strong>upgrade your plan</strong>.
                    </p>
                    <a
                        className="mg-btn is-green mg-margin-t-15"
                        href={`${Urls.MANGOOLS_PLANS_AND_PRICING_URL}?ref=msg-app-lm`}
                        rel="noopener"
                        target="_blank"
                    >
                        <strong>Upgrade my plan</strong>
                    </a>
                    <p className="font-14 color-grey">Stay cool, we have a 48h money back guarantee!</p>
                    <p className="mg-margin-t-30">
                        <a
                            className="font-14"
                            href={`${Urls.MANGOOLS_LOGIN_NO_REDIRECT_URL}?ref=msg-app-lm&redirect=${redirectRoute}`}
                        >
                            Sign in again
                        </a>
                    </p>
                </div>
            </MessageHolder>
        );
    } else {
        return null;
    }
}

LoggedOutMessage.propTypes = {
    onClose: func.isRequired,
    visible: bool.isRequired,
};

export default LoggedOutMessage;
