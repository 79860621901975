import update from 'immutability-helper';
import ActionTypes from 'constants/ActionTypes';

const initialState = {
    data: {
        citationFlow: 0,
        externalBackLinks: 0,
        refDomains: 0,
        refIPs: 0,
        trustFlow: 0,
    },
    error: {
        status: null,
        text: null,
    },
    fetching: false,
};

const urlDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DATA_URL_DATA_FETCHING: {
            return update(state, {
                fetching: { $set: true },
            });
        }
        case ActionTypes.DATA_URL_DATA_RECEIVED: {
            return update(state, {
                fetching: { $set: false },
                data: {
                    citationFlow: { $set: action.payload.citationFlow },
                    externalBackLinks: { $set: action.payload.externalBackLinks },
                    refDomains: { $set: action.payload.refDomains },
                    refIPs: { $set: action.payload.refIPs },
                    trustFlow: { $set: action.payload.trustFlow },
                },
            });
        }
        case ActionTypes.DATA_URL_DATA_ERROR: {
            return update(state, {
                data: { $set: initialState.data },
                fetching: { $set: false },
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
            });
        }
        default: {
            return state;
        }
    }
};

export default urlDataReducer;
