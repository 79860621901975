import React from 'react';

function PanelHistoryItemPreloader() {
    return (
        <div className="mg-results-tr">
            <div
                className="mg-results-td uk-width-medium-7-10 uk-width-6-10 uk-flex uk-flex-middle"
                style={{ height: '75px' }}
            >
                <div className="mg-preloader-block uk-width-6-10" />
            </div>
        </div>
    );
}

PanelHistoryItemPreloader.propTypes = {};

export default PanelHistoryItemPreloader;
