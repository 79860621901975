// String.xml like to have all UI texts in one place.
// Will help when I18n comes to mind, or just to ensure same jargon in whole app.

// TODO: migrate all string from APP here!
/* eslint-disable max-len */
const Strings = {
    components: {
        common: {
            loading: 'Preparing your drink...',
        },
    },
    messages: {
        failure: {
            delete_favorite_list_error: 'There was an error, while deleting Your favorite list.',
            download_error: 'Sorry, download is not working in Your browser. Try Chrome or Firefox.',
            export_error: 'There was an error while exporting Your data.',
            fetch_announcements_error: 'There was an error while fetching announcements.',
            fetch_favorite_list_results_error: 'There was an error while fetching Your favorite links.',
            fetch_favorite_lists_error: 'There was an error while fetching Your favorites.',
            fetch_history_error: 'There was an error while fetching requests history data.',
            fetch_limits_error: 'There was an error while fetching Your account limit data.',
            fetch_link_data_error: 'There was an error while fetching link profile data.',
            fetch_results_error: 'There was an error while fetching backlinks data.',
            footer_sorry: 'We are so sorry, try again later, please.',
            import_error: 'There was an error while importing Your keywords.',
            logout_error: 'There was an error while trying to log You out.',
            maintenance: 'Sorry, our services are undergoing a maintenance.',
            set_favorite_flag_error: 'There was an error while favoriting link.',
            set_favorite_flag_limit_error:
                'You have reached maximum of 1,000 favorite links. How about starting your outreach? :)',
            too_many_requests_error: 'Sorry, you exceeded maximum allowed API requests per second.',
            delete_history: 'Sorry, there was an error while deleting your history.',
            majestic_general_error:
                'Sorry, there was an error with our 3rd party API provider. Try again please or contact our support if the problem persists.',
            fetch_backlinks_error: 'Sorry, there was an error while fetching your exports.',
            fetch_backlinks_suggestions_error: 'Sorry, there was an error while fetching your export type suggestions.',
            create_backlinks_export: 'Sorry, there was an error while creating your export.',
            majestic_out_of_credits:
                'Sorry, there was an error with our 3rd party API provider. Try again please or contact our support if the problem persists.',
        },
    },
};
/* eslint-enable max-len */

export default Strings;
