import { createSelector } from 'reselect';
import { isNil, filter, map, pipe, uniq } from 'ramda';

import { backlinkRequestRemainingSelector } from 'selectors/userSelectors';
import { backlinkExportSuggestionsDataSelector } from 'selectors/dataSelectors';

import { exportFilterSettings, exportFilterLabels } from 'constants/Filters';
import { MAX_AVAILABLE_BACKLINK_COUNT } from 'constants/Other';

// Filter
export const filterVisibilitySelector = state => state.ui.filter.visibility;

// Messages
export const accessDeniedMessageVisibilitySelector = state => state.ui.messages.accessDenied.visibility;
export const deleteConfirmationMessageResourceNameSelector = state => state.ui.messages.deleteConfirmation.resourceName;
export const deleteConfirmationMessageResourceTypeSelector = state => state.ui.messages.deleteConfirmation.resourceType;
export const deleteConfirmationMessageVisibilitySelector = state => state.ui.messages.deleteConfirmation.visibility;
export const failureMessageDetailsSelector = state => state.ui.messages.failure.details;
export const failureMessageHeaderSelector = state => state.ui.messages.failure.header;
export const failureMessageVisibilitySelector = state => state.ui.messages.failure.visibility;
export const loggedOutMessageVisibilitySelector = state => state.ui.messages.loggedOut.visibility;
export const noConnectionMessageVisibilitySelector = state => state.ui.messages.noConnection.visibility;
export const urlTypeInfoMessageVisibilitySelector = state => state.ui.messages.urlTypeInfo.visibility;
export const needToSignInMessageVisibilitySelector = state => state.ui.messages.needToSignIn.visibility;
export const shortcutsMessageVisibilitySelector = state => state.ui.messages.shortcuts.visibility;
export const createExportSuccessMessageVisibilitySelector = state => state.ui.messages.createExportSuccess.visibility;
export const notExistingDomainMessageVisibilitySelector = state => state.ui.messages.notExistingDomain.visibility;

export const createExportSuccessMessageReadyInSelector = state => state.ui.messages.createExportSuccess.readyIn;
export const notExistingDomainMessageDomainSelector = state => state.ui.messages.notExistingDomain.domain;

// TODO: Only show pricing message when needToSignIn is not visible
export const pricingMessageVisibilitySelector = state =>
    state.ui.messages.pricing.visibility && !needToSignInMessageVisibilitySelector(state);

// Dropdowns
export const exportDropdownVisibilitySelector = state => state.ui.dropdowns.export.visibility;
export const helpDropdownVisibilitySelector = state => state.ui.dropdowns.help.visibility;
export const userDropdownVisibilitySelector = state => state.ui.dropdowns.user.visibility;

// Returns true if any of the dropdowns is visible
export const anyDropdownVisibleSelector = createSelector(
    [exportDropdownVisibilitySelector, helpDropdownVisibilitySelector, userDropdownVisibilitySelector],
    (exportDropdownVisible, helpDropdownVisible) =>
        exportDropdownVisible || helpDropdownVisible,
);

// Panels
export const appPanelVisibilitySelector = state => state.ui.panels.app.visibility;
export const favoritesPanelVisibilitySelector = state => state.ui.panels.favorites.visibility;
export const historyPanelVisibilitySelector = state => state.ui.panels.history.visibility;

// Misc
export const newVersionNotificationShownSelector = state => state.ui.misc.newVersionNotificationShown;
export const onlineStatusSelector = state => state.ui.misc.onlineStatus;
export const navigatedInternallySelector = state => state.ui.misc.navigatedInternally;
export const colorSchemeSelector = state => state.ui.misc.colorScheme;
export const resultTableScrollLeftSelector = state => state.ui.misc.resultTableScrollLeft;

export const overlayVisibilitySelector = createSelector(
    [
        appPanelVisibilitySelector,
        historyPanelVisibilitySelector,
        favoritesPanelVisibilitySelector,
        failureMessageVisibilitySelector,
    ],
    (appVisibility, historyVisibility, favoritesVisibility, failure) =>
        !failure && (appVisibility || historyVisibility || favoritesVisibility),
);

export const mobileOverlayVisibilitySelector = createSelector(
    [anyDropdownVisibleSelector],
    anyDropdownVisible => anyDropdownVisible,
);

// Dashboard
export const dashboardFavoritingLinkIdSelector = state => state.ui.dashboard.favoritingLinkId;
export const websitePreviewSizeSelector = state => state.ui.dashboard.websitePreview.size;
export const websitePreviewVisibilitySelector = state => state.ui.dashboard.websitePreview.visibility;

// create export
export const createExportFormDataSelector = state => state.ui.createExport;
export const createExportFilterActiveSelector = state => state.ui.createExport.filter.active;
export const createExportFilterVisibilitySelector = state => state.ui.createExport.filter.visibility;
export const createExportFilterSettingsSelector = state => state.ui.createExport.filter.settings;
export const createExportLimitSelector = state => state.ui.createExport.limit;
export const createExportSelectedSuggestionUrlSelector = state => state.ui.createExport.selectedSuggestionUrl;

export const createExportSelectedSuggestionSelector = createSelector(
    [backlinkExportSuggestionsDataSelector, createExportFormDataSelector],
    (suggestions, createExportForm) => {
        return suggestions.suggestionsByIds[createExportForm.selectedSuggestionUrl];
    },
);

export const createExportLimitMaxSelector = createSelector(
    [backlinkRequestRemainingSelector, createExportSelectedSuggestionSelector],
    (backlinksRemaining, suggestion) => {
        if (!isNil(suggestion)) {
            if (suggestion.cost >= MAX_AVAILABLE_BACKLINK_COUNT && backlinksRemaining >= MAX_AVAILABLE_BACKLINK_COUNT) {
                return MAX_AVAILABLE_BACKLINK_COUNT;
            } else if (
                suggestion.cost >= MAX_AVAILABLE_BACKLINK_COUNT &&
                backlinksRemaining < MAX_AVAILABLE_BACKLINK_COUNT
            ) {
                return backlinksRemaining;
            } else if (suggestion.cost < MAX_AVAILABLE_BACKLINK_COUNT && suggestion.cost < backlinksRemaining) {
                return suggestion.cost;
            } else {
                return backlinksRemaining;
            }
        } else {
            return MAX_AVAILABLE_BACKLINK_COUNT;
        }
    },
);

export const createExportOverLimitVisiblitySelector = createSelector(
    [
        backlinkRequestRemainingSelector,
        createExportSelectedSuggestionSelector,
        createExportLimitSelector,
        createExportLimitMaxSelector,
    ],
    (backlinksRemaining, suggestion, limit, limitMax) => {
        if (suggestion && suggestion.cost > backlinksRemaining && limit === limitMax) {
            return true;
        } else {
            return false;
        }
    },
);

export const createExportSliderStepSelector = createSelector(createExportLimitMaxSelector, limitMax => {
    if (limitMax > 10000) {
        return 1000;
    } else if (limitMax > 1000) {
        return 100;
    } else {
        return 10;
    }
});

export const createExportSliderMinSelector = createSelector(createExportLimitMaxSelector, limitMax => {
    if (limitMax > 10000) {
        return 1000;
    } else {
        return 100;
    }
});

export const createExportLimitRoundedMaxSelector = createSelector(
    [createExportLimitMaxSelector, createExportSliderStepSelector],
    (max, step) => {
        if (step === 1000 && max % 1000 !== 0) {
            return Math.ceil(max / 1000) * 1000;
        } else if (step === 100 && max % 100 !== 0) {
            return Math.ceil(max / 100) * 100;
        } else {
            return Math.ceil(max / 10) * 10;
        }
    },
);

export const createExportActiveFiltersSelector = createSelector(createExportFilterSettingsSelector, settings => {
    return pipe(
        filter(([key, value]) => value !== exportFilterSettings[key].initialValue),
        map(([key]) => exportFilterLabels[key]),
        uniq,
    )(Object.entries(settings));
});

const anyMessageVisibilitySelector = createSelector(
    [
        accessDeniedMessageVisibilitySelector,
        deleteConfirmationMessageVisibilitySelector,
        failureMessageVisibilitySelector,
        loggedOutMessageVisibilitySelector,
        noConnectionMessageVisibilitySelector,
        urlTypeInfoMessageVisibilitySelector,
        needToSignInMessageVisibilitySelector,
        shortcutsMessageVisibilitySelector,
        pricingMessageVisibilitySelector,
        createExportSuccessMessageVisibilitySelector,
    ],
    (
        accessDeniedMessageVisible,
        deleteConfirmationMessageVisible,
        failureMessageVisible,
        loggedOutMessageVisible,
        noConnectionMessageVisible,
        urlTypeInfoMessageVisible,
        needToSignInMessageVisible,
        shortcutsMessageVisible,
        pricingMessageVisible,
        createExportSuccessMessageVisible,
    ) =>
        accessDeniedMessageVisible ||
        deleteConfirmationMessageVisible ||
        failureMessageVisible ||
        loggedOutMessageVisible ||
        noConnectionMessageVisible ||
        urlTypeInfoMessageVisible ||
        needToSignInMessageVisible ||
        shortcutsMessageVisible ||
        pricingMessageVisible ||
        createExportSuccessMessageVisible,
);

const anyPanelVisibilitySelector = createSelector(
    [appPanelVisibilitySelector, favoritesPanelVisibilitySelector, historyPanelVisibilitySelector],
    (appPanelVisible, favoritesPanelVisible, historyPanelVisible) =>
        appPanelVisible || favoritesPanelVisible || historyPanelVisible,
);

const anyDropdownVisibilitySelector = createSelector(
    [exportDropdownVisibilitySelector, helpDropdownVisibilitySelector, userDropdownVisibilitySelector],
    (exportDropdownVisible, helpDropdownVisible, userDropdownVisible) =>
        exportDropdownVisible || helpDropdownVisible || userDropdownVisible,
);

export const areShortcutsDisabledSelector = createSelector(
    [anyMessageVisibilitySelector, anyDropdownVisibilitySelector, anyPanelVisibilitySelector],
    (anyMessageVisible, anyDropdownVisible, anyPanelVisible) =>
        anyMessageVisible || anyDropdownVisible || anyPanelVisible,
);
