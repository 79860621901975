const SortingColumns = {
    TOP_RANK: 'topRank',
    ANCHOR: 'anchor',
    BACKLINK_URL: 'sourceUrl',
    CITATION_FLOW: 'citationFlow',
    EXTERNAL_LINKS: 'externalLinks',
    FACEBOOK_SHARES: 'facebookShares',
    LINK_STRENGTH: 'linkStrength',
    TARGET_URL: 'targetUrl',
    TRUST_FLOW: 'trustFlow',
};

export default SortingColumns;
