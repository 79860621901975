const ALL_LINKS = 'ALL_LINKS';
const FAVORITE_LIST = 'FAVORITE_LIST';
const LOST_LINKS = 'LOST_LINKS';
const NEW_LINKS = 'NEW_LINKS';

export const getTypeFromSourceId = sourceid => {
    switch (sourceid) {
        case 0: {
            return ALL_LINKS;
        }
        case 1: {
            return NEW_LINKS;
        }
        case 2: {
            return LOST_LINKS;
        }
        default: {
            return ALL_LINKS;
        }
    }
};

export default {
    ALL_LINKS,
    FAVORITE_LIST,
    LOST_LINKS,
    NEW_LINKS,
};
