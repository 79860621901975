import ActionTypes from 'constants/ActionTypes';
import update from 'immutability-helper';

import concat from 'ramda/src/concat';
import includes from 'ramda/src/includes';

const initialState = {
    data: [],
    error: {
        status: null,
        text: null,
    },
    fetching: false,
};

const resultsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DATA_FAVORITE_LISTS_DELETE_FETCHING:
        case ActionTypes.DATA_FAVORITE_LISTS_FETCHING: {
            return update(state, {
                fetching: { $set: true },
            });
        }
        case ActionTypes.DATA_FAVORITE_LISTS_RECEIVED: {
            return update(state, {
                data: { $set: action.payload },
                fetching: { $set: false },
                error: {
                    status: { $set: initialState.error.status },
                    text: { $set: initialState.error.text },
                },
            });
        }
        case ActionTypes.DATA_FAVORITE_LISTS_DELETE_RECEIVED: {
            return update(state, {
                data: { $apply: items => items.filter(item => item.id !== action.payload) },
                fetching: { $set: false },
                error: {
                    status: { $set: initialState.error.status },
                    text: { $set: initialState.error.text },
                },
            });
        }
        case ActionTypes.DATA_RESULTS_FAVORITE_FLAG_SET_RECEIVED: {
            const list = action.payload.favoriteList;
            const allIds = state.data.map(item => item.id);
            const alreadyExists = includes(list.id, allIds);

            if (alreadyExists) {
                return update(state, {
                    data: {
                        $apply: items =>
                            items.map(item => {
                                if (item.id === list.id) {
                                    return update(item, {
                                        linkIds: { $set: list.linkIds },
                                    });
                                } else {
                                    return item;
                                }
                            }),
                    },
                });
            } else {
                return update(state, {
                    data: {
                        $apply: items => concat(items, [list]),
                    },
                });
            }
        }
        case ActionTypes.DATA_FAVORITE_LISTS_DELETE_ERROR: {
            return update(state, {
                fetching: { $set: false },
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
            });
        }
        case ActionTypes.DATA_FAVORITE_LISTS_ERROR: {
            return update(state, {
                data: { $set: initialState.data },
                fetching: { $set: false },
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
            });
        }
        default: {
            return state;
        }
    }
};

export default resultsReducer;
