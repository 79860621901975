import { isEmpty, isNil, toLower } from 'ramda';

import LinkRelTypes from 'constants/LinkRelTypes';
import LinkStatusTypes from 'constants/LinkStatusTypes';

/**
 * Filter service for backlink result data
 */
class ResultFilterService {
    /**
     * Main filter function.
     *
     * @param {data: Arrays} Array of data.
     * @param {settings: Object} Settings object.
     * @return {Array} Filtered data.
     */
    static filter({ data, settings }) {
        const { advanced, quick } = settings;

        // Only if there are some results
        if (data.length > 0) {
            // Filter link status (active / deleleted)
            const filteredLinkStatus = this.filterQuickFilterLinkStatusTypes(data, quick.linkStatusType);

            // Filter link rel (do follow / no follow)
            const filteredLinkRel = this.filterQuickFilterLinkRelTypes(filteredLinkStatus, quick.linkRelType);

            // Filter by sourceUrl, targetUrl and Title
            const filteredBySearch = this.filterQuickFilterSearch(filteredLinkRel, quick.search);

            // Advanced filter only if active
            if (advanced.active === true) {
                // Filtering min values
                const filteredMin = this.filterMinValues(filteredBySearch, advanced);

                // Filtering to values
                const filteredMax = this.filterMaxValues(filteredMin, advanced);

                // Filtering URL
                const filteredUrl = this.filterSubstring({
                    data: filteredMax,
                    field: 'sourceUrl',
                    filterString: advanced.urlFilter,
                });

                // Filtering Href
                const filteredHref = this.filterSubstring({
                    data: filteredUrl,
                    field: 'targetUrl',
                    filterString: advanced.hrefFilter,
                });

                // Filtering anchor
                const filteredAnchor = this.filterSubstring({
                    data: filteredHref,
                    field: 'anchor',
                    filterString: advanced.anchorFilter,
                });

                return filteredAnchor;
            } else {
                return filteredBySearch;
            }
        } else {
            return data;
        }
    }

    static filterQuickFilterSearch(data, search) {
        const lowerCasedSearch = search.trim().toLowerCase();

        return data.filter(
            ({ title, targetUrl, sourceUrl, anchor }) =>
                title.toLowerCase().includes(lowerCasedSearch) ||
                targetUrl.includes(lowerCasedSearch) ||
                sourceUrl.includes(lowerCasedSearch) ||
                anchor.includes(lowerCasedSearch),
        );
    }

    static filterQuickFilterLinkRelTypes(data, linkRelTypeObj) {
        let filtered = data;
        const linkRelType = linkRelTypeObj.value;

        if (linkRelType === LinkRelTypes.DO_FOLLOW) {
            filtered = filtered.filter(item => item.flags.noFollow === false);
        } else if (linkRelType === LinkRelTypes.NO_FOLLOW) {
            filtered = filtered.filter(item => item.flags.noFollow === true);
        }

        return filtered;
    }

    static filterQuickFilterLinkStatusTypes(data, linkStatusTypeObj) {
        let filtered = data;
        const linkStatusType = linkStatusTypeObj.value;

        if (linkStatusType === LinkStatusTypes.DELETED) {
            filtered = filtered.filter(item => item.flags.deleted === true);
        } else if (linkStatusType === LinkStatusTypes.ACTIVE) {
            filtered = filtered.filter(item => item.flags.deleted === false);
        }

        return filtered;
    }

    static filterMinValues(data, settings) {
        let fromFiltered = data;

        // Handle link strength value
        fromFiltered = fromFiltered.filter(item => {
            if (settings.minLinkStrength === 0) {
                return true;
            } else {
                return item.linkStrength >= settings.minLinkStrength;
            }
        });

        // Handle citationFlow
        fromFiltered = fromFiltered.filter(item => {
            if (settings.minCitationFlow === 0) {
                return true;
            } else {
                return item.citationFlow >= settings.minCitationFlow;
            }
        });

        // Handle trustFlow
        fromFiltered = fromFiltered.filter(item => {
            if (settings.minTrustFlow === 0) {
                return true;
            } else {
                return item.trustFlow >= settings.minTrustFlow;
            }
        });

        // Handle external links
        fromFiltered = fromFiltered.filter(item => {
            if (settings.minExternalLinks === 0) {
                return true;
            } else {
                return item.externalLinks >= settings.minExternalLinks;
            }
        });

        // Handle top rank
        fromFiltered = fromFiltered.filter(item => {
            if (settings.minTopRank === 0) {
                return true;
            } else {
                return item.topRank >= settings.minTopRank;
            }
        });

        return fromFiltered;
    }

    static filterMaxValues(data, settings) {
        let filteredTo = data;
        const { maxTopRank, maxLinkStrength, maxCitationFlow, maxExternalLinks, maxTrustFlow } = settings;

        if (!isNil(maxLinkStrength)) {
            filteredTo = filteredTo.filter(item => item.linkStrength <= maxLinkStrength);
        }

        if (!isNil(maxCitationFlow)) {
            filteredTo = filteredTo.filter(item => item.citationFlow <= maxCitationFlow);
        }

        if (!isNil(maxTrustFlow)) {
            filteredTo = filteredTo.filter(item => item.trustFlow <= maxTrustFlow);
        }

        if (!isNil(maxExternalLinks)) {
            filteredTo = filteredTo.filter(item => item.externalLinks <= maxExternalLinks);
        }

        if (!isNil(maxTopRank)) {
            filteredTo = filteredTo.filter(item => item.topRank <= maxTopRank);
        }

        return filteredTo;
    }

    static filterSubstring({ data, field, filterString }) {
        if (!isEmpty(filterString)) {
            return data.filter(item => {
                const itemField = item[field];

                if (!isEmpty(itemField)) {
                    return toLower(itemField).indexOf(toLower(filterString)) !== -1;
                } else {
                    return false;
                }
            });
        } else {
            return data;
        }
    }
}

export default ResultFilterService;
