import ActionTypes from 'constants/ActionTypes';
import update from 'immutability-helper';
import { prepend } from 'ramda';

export const initialState = {
    data: [],
    error: {
        status: null,
        text: null,
    },
    fetching: false,
    suggestionsData: {
        allIds: [],
        byIds: {},
    },
    suggestionsError: {
        status: null,
        text: null,
    },
    suggestionsFetching: false,
    fetchingNew: false,
    errorNew: {
        status: null,
        text: null,
    },
};

const backlinkExportReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.DATA_NEW_BACKLINK_EXPORT_FETCHING:
            return update(state, {
                suggestionsData: { $set: initialState.suggestionsData },
            });
        case ActionTypes.DATA_BACKLINK_EXPORTS_FETCHING:
            return update(state, {
                fetching: { $set: true },
            });
        case ActionTypes.DATA_BACKLINK_EXPORTS_RECEIVED:
            return update(state, {
                data: { $set: payload },
                fetching: { $set: false },
                error: {
                    status: { $set: null },
                    text: { $set: null },
                },
            });
        case ActionTypes.DATA_BACKLINK_EXPORTS_ERROR:
            return update(state, {
                error: {
                    status: { $set: null },
                    text: { $set: null },
                },
                fetching: { $set: false },
            });
        case ActionTypes.DATA_BACKLINK_EXPORT_SUGGESTIONS_RESET:
            return update(state, {
                suggestionsData: { $set: initialState.suggestionsData },
            });
        case ActionTypes.DATA_BACKLINK_EXPORT_SUGGESTIONS_REQUESTED:
            return update(state, {
                suggestionsFetching: { $set: true },
            });
        case ActionTypes.DATA_BACKLINK_EXPORT_SUGGESTIONS_RECEIVED: {
            return update(state, {
                suggestionsFetching: { $set: false },
                suggestionsData: { $set: payload },
                suggestionsError: {
                    status: { $set: null },
                    text: { $set: null },
                },
            });
        }
        case ActionTypes.DATA_BACKLINK_EXPORT_SUGGESTIONS_ERROR: {
            return update(state, {
                suggestionsError: {
                    status: { $set: null },
                    text: { $set: null },
                },
                suggestionsFetching: { $set: false },
            });
        }
        case ActionTypes.DATA_NEW_BACKLINK_EXPORT_RECEIVED: {
            return update(state, {
                fetchingNew: { $set: false },
                errorNew: {
                    status: { $set: null },
                    text: { $set: null },
                },
                data: { $apply: data => prepend(payload, data) },
            });
        }
        case ActionTypes.UI_DASHBOARD_RESET:
            return update(state, {
                $set: update(initialState, {
                    data: { $set: state.data },
                }),
            });
        default:
            return state;
    }
};

export default backlinkExportReducer;
