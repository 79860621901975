import times from 'ramda/src/times';

const PRELOADER_RESULT_COUNT = 5;

const results = times(
    () => ({
        preloader: true,
    }),
    PRELOADER_RESULT_COUNT,
);

export const PRELOADER_RESULTS = results;

export const MAX_TOP_RANK = Number.MAX_VALUE;

export const BACKLINK_PAGE_SIZE = 500;

export const MAX_AVAILABLE_BACKLINK_COUNT = 50000;

export const MIN_AVAILABLE_BACKLINK_EXPORT_COUNT = 100;

export const FORMATTED_MAX_MAJESTIC_FRESH_INDEX_AGE = '3 m';
