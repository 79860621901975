import update from 'immutability-helper';
import ActionTypes from 'constants/ActionTypes';
import DataSourceTypes from 'constants/DataSourceTypes';

const initialState = {
    linksPerDomain: 1,
    page: 1,
    source: DataSourceTypes.ALL_LINKS,
    url: '',
};

const paramsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.PARAMS_SET: {
            return update(state, {
                linksPerDomain: { $set: action.payload.linksPerDomain },
                page: { $set: action.payload.page },
                source: { $set: action.payload.source },
                url: { $set: action.payload.url },
            });
        }
        default: {
            return state;
        }
    }
};

export default paramsReducer;
