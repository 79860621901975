import { parseLink } from 'sources/parsers/ResultDataParser';

export const parseItem = rawItem => ({
    createdAt: rawItem.created_at * 1000,
    domain: rawItem.domain,
    id: rawItem._id, // eslint-disable-line no-underscore-dangle
    linkIds: rawItem.link_ids,
});

// TODO:
export const parseIndex = rawData => rawData.map(item => parseItem(item));

export const parseDetail = rawData => ({
    createdAt: rawData.created_at * 1000,
    domain: rawData.domain,
    id: rawData._id, // eslint-disable-line no-underscore-dangle
    links: rawData.links.map(item => parseLink(item)),
});
