import React, { useEffect } from 'react';
import { useVariant } from '@unleash/proxy-client-react';
import { useDispatch } from 'react-redux';

import { bool, number } from 'prop-types';
import Urls from 'mangools-commons/lib/constants/Urls';
import Timer from 'components/messages/topLevel/newUserMessage/Timer';
import { gtmTrack } from 'actions/analyticsActions';

import upgradeImg from 'images/gift.svg';

function NewUserMessage(props) {
    const dispatch = useDispatch();
    const variant = useVariant('early-bird-ab-test');
    const isOneTimeVariant = variant.enabled && variant.name === 'onetime-earlybird';

    useEffect(() => {
        dispatch(
            gtmTrack({
                event: 'unleash',
                ABTestName: 'earlybird',
                ABTestVariant: variant.name,
            }),
        );
    }, []);

    if (props.visible) {
        return (
            <div className="mg-page-message">
                <div className="uk-container uk-container-center uk-flex uk-flex-middle uk-flex-center">
                    <img
                        src={upgradeImg}
                        alt="Upgrade subscription"
                        width="18"
                        className="mg-margin-r-10"
                        style={{ marginTop: '-3px' }}
                    />
                    <strong>Get 10% {isOneTimeVariant ? '' : 'LIFETIME'} discount&nbsp;</strong> on Mangools SEO
                    tools!&nbsp;
                    <Timer limit={props.timerLimitSeconds} />
                    <span className="uk-hidden-medium mg-margin-l-10">Hurry up, time is running.&nbsp;</span>
                    <a
                        className="mg-btn is-orange is-gradient is-pill is-xsmall mg-margin-l-15"
                        href={`${Urls.MANGOOLS_PLANS_AND_PRICING_URL}?ref=msg-app-lm`}
                        rel="noopener"
                        target="_blank"
                    >
                        Grab discount
                    </a>
                </div>
            </div>
        );
    } else {
        return null;
    }
}

NewUserMessage.propTypes = {
    timerLimitSeconds: number.isRequired,
    visible: bool.isRequired,
};

export default NewUserMessage;
