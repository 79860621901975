import ActionTypes from 'constants/ActionTypes';

export const closeAll = () => ({
    type: ActionTypes.UI_ALL_CLOSE,
});

export const showAccessDeniedMessage = () => ({
    type: ActionTypes.UI_MESSAGES_ACCESS_DENIED_SHOW,
});

export const closeAccessDeniedMessage = () => ({
    type: ActionTypes.UI_MESSAGES_ACCESS_DENIED_CLOSE,
});

export const showNeedToSignInMessage = () => ({
    type: ActionTypes.UI_MESSAGES_NEED_TO_SIGN_IN_SHOW,
});

export const closeNeedToSignInMessage = () => ({
    type: ActionTypes.UI_MESSAGES_NEED_TO_SIGN_IN_CLOSE,
});

export const showPricingMessage = () => ({
    type: ActionTypes.UI_MESSAGES_PRICING_SHOW,
});

export const closePricingMessage = () => ({
    type: ActionTypes.UI_MESSAGES_PRICING_CLOSE,
});

export const showFailureMessage = ({ details, header }) => ({
    type: ActionTypes.UI_MESSAGES_FAILURE_SHOW,
    error: false,
    payload: { details, header },
});

export const closeFailureMessage = () => ({
    type: ActionTypes.UI_MESSAGES_FAILURE_CLOSE,
});

export const setNavigatedInternally = () => ({
    type: ActionTypes.UI_MISC_NAVIGATED_INTERNALLY_SET,
});

export const setNewVersionNotificationShown = () => ({
    type: ActionTypes.UI_MISC_NEW_VERSION_NOTIFICATION_SHOWN_SET,
});

export const receivedErrorNotificationAction = (message, config) => ({
    type: ActionTypes.UI_MISC_NOTIFICATIONS_ERROR_SHOW,
    error: false,
    payload: { message, config },
});

export const receivedInfoNotificationAction = (message, config) => ({
    type: ActionTypes.UI_MISC_NOTIFICATIONS_INFO_SHOW,
    error: false,
    payload: { message, config },
});

export const requestedCloseAllNotificationsAction = () => ({
    type: ActionTypes.UI_MISC_NOTIFICATIONS_CLOSE_ALL,
});

export const showDeleteConfirmationMessage = payload => ({
    type: ActionTypes.UI_MESSAGES_DELETE_CONFIRMATION_SHOW,
    error: false,
    payload,
});

export const closeDeleteConfirmationMessage = () => ({
    type: ActionTypes.UI_MESSAGES_DELETE_CONFIRMATION_CLOSE,
});

export const confirmDeleteConfirmationMessage = () => ({
    type: ActionTypes.UI_MESSAGES_DELETE_CONFIRMATION_CONFIRM,
});

export const closeHelpDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_HELP_CLOSE,
});

export const showHelpDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_HELP_SHOW,
});

export const showExportDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_EXPORT_SHOW,
});

export const closeExportDropdown = () => ({
    type: ActionTypes.UI_DROPDOWNS_EXPORT_CLOSE,
});

export const setOnlineStatus = ({ onlineStatus }) => ({
    type: ActionTypes.UI_MISC_ONLINE_STATUS_SET,
    error: false,
    payload: {
        onlineStatus,
    },
});

export const showNoConnectionMessage = () => ({
    type: ActionTypes.UI_MESSAGES_NO_CONNECTION_SHOW,
});

export const closeNoConnectionMessage = () => ({
    type: ActionTypes.UI_MESSAGES_NO_CONNECTION_CLOSE,
});

export const showAppPanel = () => ({
    type: ActionTypes.UI_PANELS_APP_SHOW,
});

export const closeAppPanel = () => ({
    type: ActionTypes.UI_PANELS_APP_CLOSE,
});

export const showHistoryPanel = () => ({
    type: ActionTypes.UI_PANELS_HISTORY_SHOW,
});

export const closeHistoryPanel = () => ({
    type: ActionTypes.UI_PANELS_HISTORY_CLOSE,
});

export const showFavoritesPanel = () => ({
    type: ActionTypes.UI_PANELS_FAVORITES_SHOW,
});

export const closeFavoritesPanel = () => ({
    type: ActionTypes.UI_PANELS_FAVORITES_CLOSE,
});

export const setCurrentResult = resultId => ({
    type: ActionTypes.UI_DASHBOARD_CURRENT_RESULT_SET,
    error: false,
    payload: resultId,
});

export const setSortingSettings = ({ column, type }) => ({
    type: ActionTypes.UI_DASHBOARD_SORTING_SETTINGS_SET,
    error: false,
    payload: { column, type },
});

export const resetFilter = () => ({
    type: ActionTypes.UI_FILTER_RESET,
});

export const setAdvancedFilterActive = payload => ({
    type: ActionTypes.UI_FILTER_ADVANCED_ACTIVE_SET,
    error: false,
    payload,
});

export const setFilterQuickLinkRelType = type => ({
    type: ActionTypes.UI_FILTER_QUICK_LINK_REL_TYPE_SET,
    error: false,
    payload: type,
});

export const setFilterQuickLinkStatusType = status => ({
    type: ActionTypes.UI_FILTER_QUICK_LINK_STATUS_TYPE_SET,
    error: false,
    payload: status,
});

export const setFilterSettings = payload => ({
    type: ActionTypes.UI_FILTER_SETTINGS_SET,
    error: false,
    payload,
});

export const toggleFilterVisibility = () => ({
    type: ActionTypes.UI_FILTER_VISIBILITY_TOGGLE,
});

export const setFilterVisibility = visible => ({
    type: ActionTypes.UI_FILTER_VISIBILITY_SET,
    error: false,
    payload: visible,
});

export const resetDashboard = () => ({
    type: ActionTypes.UI_DASHBOARD_RESET,
});

export const setCurrentDashboardDataSource = ({ id, name, type }) => ({
    type: ActionTypes.UI_DASHBOARD_CURRENT_DATA_SOURCE_SET,
    error: false,
    payload: {
        id,
        name,
        type,
    },
});

export const showUrlTypeInfoMessage = () => ({
    type: ActionTypes.UI_MESSAGES_URL_TYPE_INFO_SHOW,
});

export const closeUrlTypeInfoMessage = () => ({
    type: ActionTypes.UI_MESSAGES_URL_TYPE_INFO_CLOSE,
});

export const closeTopLevelAnnouncementMessage = () => ({
    type: ActionTypes.UI_MESSAGES_TOP_LEVEL_ANNOUNCEMENT_CLOSE,
});

export const showLoggedOutMessage = () => ({
    type: ActionTypes.UI_MESSAGES_LOGGED_OUT_SHOW,
});

export const closeLoggedOutMessage = () => ({
    type: ActionTypes.UI_MESSAGES_LOGGED_OUT_CLOSE,
});

export const closeShortcutsMessage = () => ({
    type: ActionTypes.UI_MESSAGES_SHORTCUTS_CLOSE,
});

export const showShortcutsMessage = () => ({
    type: ActionTypes.UI_MESSAGES_SHORTCUTS_SHOW,
});

export const setQuickFilterSearch = payload => ({
    type: ActionTypes.UI_FILTER_QUICK_SEARCH_SET,
    payload,
});

export const setWebsitePreviewSize = payload => ({
    type: ActionTypes.UI_DASHBOARD_WEBSITE_PREVIEW_SIZE_SET,
    payload,
});

export const setWebsitePreviewVisiblity = payload => ({
    type: ActionTypes.UI_DASHBOARD_WEBSITE_PREVIEW_VISIBILITY_SET,
    payload,
});

export const setColorScheme = payload => ({
    type: ActionTypes.UI_MISC_COLOR_SCHEME_SET,
    payload,
});

export const setResultTableHorizontalScroll = scrollLeft => ({
    type: ActionTypes.UI_MISC_RESULT_TABLE_HORIZONTAL_SCROLL_SET,
    payload: scrollLeft,
});

export const showCreateExportSuccessMessage = readyIn => ({
    type: ActionTypes.UI_MESSAGES_CREATE_EXPORT_SUCCESS_SHOW,
    payload: readyIn,
});

export const closeCreateExportSuccessMessage = () => ({
    type: ActionTypes.UI_MESSAGES_CREATE_EXPORT_SUCCESS_CLOSE,
});

export const showNotExistingDomainMessage = domain => ({
    type: ActionTypes.UI_MESSAGES_NOT_EXISTING_DOMAIN_SHOW,
    payload: domain,
});

export const closeNotExistingDomainMessage = () => ({
    type: ActionTypes.UI_MESSAGES_NOT_EXISTING_DOMAIN_CLOSE,
});

export const resetCreateExportFilter = () => ({
    type: ActionTypes.UI_CREATE_EXPORT_FILTER_RESET,
});

export const setCreateExportFilterActive = payload => ({
    type: ActionTypes.UI_CREATE_EXPORT_FILTER_ACTIVE_SET,
    error: false,
    payload,
});

export const setCreateExportFilterSettings = payload => ({
    type: ActionTypes.UI_CREATE_EXPORT_FILTER_SETTINGS_SET,
    error: false,
    payload,
});

export const toggleCreateExportFilterVisibility = () => ({
    type: ActionTypes.UI_CREATE_EXPORT_FILTER_VISIBILITY_TOGGLE,
});

export const setCreateExportUrl = url => ({
    type: ActionTypes.UI_CREATE_EXPORT_URL_SET,
    payload: url,
});

export const setCreateExportBacklinkLimit = limit => ({
    type: ActionTypes.UI_CREATE_EXPORT_LIMIT_SET,
    payload: limit,
});

export const setCreateExportSelectedSuggestionUrl = url => ({
    type: ActionTypes.UI_CREATE_EXPORT_SELECTED_SUGGESTION_URL_SET,
    payload: url,
});
