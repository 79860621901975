import isNil from 'ramda/src/isNil';

import { parseIntWithNAFallback, parseStringWithNAFallback } from 'sources/parsers/commons';
import { MAX_TOP_RANK } from 'constants/Other';
import LinkTypes from 'constants/LinkTypes';
import UrlTypes from 'constants/UrlTypes';

function parseType(rawType) {
    switch (rawType) {
        case 'ImageLink': {
            return LinkTypes.IMAGE_LINK;
        }
        case 'TextLink': {
            return LinkTypes.TEXT_LINK;
        }
        case 'Frame': {
            return LinkTypes.Frame;
        }
        case 'Redirect': {
            return LinkTypes.REDIRECT;
        }
        case 'Mention': {
            return LinkTypes.MENTION;
        }
        default: {
            return LinkTypes.OTHER;
        }
    }
}

export function parseUrlType(rawUrlType) {
    switch (rawUrlType) {
        case 'URL': {
            return UrlTypes.EXACT_URL;
        }
        case 'SubDomain': {
            return UrlTypes.SUBDOMAIN;
        }
        case 'RootDomain': {
            return UrlTypes.ROOT_DOMAIN;
        }
        default: {
            return UrlTypes.EXACT_URL;
        }
    }
}

function parseTopRank(rawTopRank) {
    if (isNil(rawTopRank)) {
        return MAX_TOP_RANK;
    } else {
        return parseInt(rawTopRank, 10);
    }
}

export const parseLink = rawLink => ({
    topRank: parseTopRank(rawLink.ar),
    anchor: parseStringWithNAFallback(rawLink.anchor),
    citationFlow: parseIntWithNAFallback(rawLink.source_cf),
    externalLinks: parseIntWithNAFallback(rawLink.external_links),
    facebookShares: parseIntWithNAFallback(rawLink.fb),
    flags: {
        alt: !!rawLink.alt, // NOTE: can be undefined, so converting to bool
        deleted: rawLink.deleted,
        frame: rawLink.frame,
        image: rawLink.image,
        mention: !!rawLink.mention, // NOTE: can be undefined, so converting to bool
        noFollow: rawLink.no_follow,
        redirect: rawLink.redirect,
    },
    id: rawLink._id, // eslint-disable-line no-underscore-dangle
    internalLinks: parseIntWithNAFallback(rawLink.internal_links),
    language: parseStringWithNAFallback(rawLink.lang),
    lastCrawl: new Date(rawLink.last_crawl).getTime(),
    lastSeen: new Date(rawLink.last_seen).getTime(),
    linkStrength: parseInt(rawLink.ls, 10),
    sourceUrl: rawLink.source,
    targetUrl: rawLink.target,
    title: parseStringWithNAFallback(rawLink.title),
    trustFlow: parseIntWithNAFallback(rawLink.source_tf),
    type: parseType(rawLink.type),
    url: parseStringWithNAFallback(rawLink.url),
});

export const parse = rawData => ({
    meta: {
        urlType: parseUrlType(rawData.meta.url_type),
    },
    links: rawData.links.map(item => parseLink(item)),
});
