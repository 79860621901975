import update from 'immutability-helper';
import ActionTypes from 'constants/ActionTypes';

const initialState = {
    export: {
        visibility: false,
    },
    help: {
        visibility: false,
    },
    user: {
        visibility: false,
    },
};

const dropdownsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UI_DROPDOWNS_EXPORT_CLOSE: {
            return update(state, {
                export: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_EXPORT_SHOW: {
            return update(state, {
                export: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_HELP_CLOSE: {
            return update(state, {
                help: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_DROPDOWNS_HELP_SHOW: {
            return update(state, {
                help: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_ALL_CLOSE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default dropdownsReducer;
