import update from 'immutability-helper';
import { isNil, isEmpty, trim as Rtrim } from 'ramda';

class FilterService {
    static formatValue(value) {
        return isNil(value) || value === 0 ? '' : `${value}`;
    }

    static parseMinToInt(inputValue) {
        return !isEmpty(inputValue) ? parseInt(inputValue, 10) : 0;
    }

    static parseMaxToInt(inputValue) {
        return !isEmpty(inputValue) ? parseInt(inputValue, 10) : null;
    }

    static trim(inputValue) {
        return Rtrim(inputValue);
    }

    static createInitialStoreState(filter) {
        return Object.entries(filter).reduce((filters, [key, { initialValue }]) => {
            return update(filters, { $merge: { [key]: initialValue } });
        }, {});
    }

    static formatForSubmit(filterValues, filter) {
        return Object.entries(filter).reduce((values, [filterKey, { submitFormatter }]) => {
            return update(values, {
                $merge: {
                    [filterKey]: isNil(submitFormatter)
                        ? filterValues[filterKey]
                        : submitFormatter(filterValues[filterKey]),
                },
            });
        }, {});
    }

    static initState(filterSettings, filter) {
        return Object.entries(filter).reduce((data, [key, { filterFormatter }]) => {
            return update(data, {
                $merge: {
                    [key]: isNil(filterFormatter) ? filterSettings[key] : filterFormatter(filterSettings[key]),
                },
            });
        }, {});
    }
}

export default FilterService;
