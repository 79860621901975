import React, { Component } from 'react';
import { number } from 'prop-types';

class Timer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            remaining: this.props.limit,
        };

        this.tick = this.tick.bind(this);
        this.remainingSeconds = this.remainingSeconds.bind(this);
        this.remainingMinutes = this.remainingMinutes.bind(this);
    }

    componentDidMount() {
        this.timer = setInterval(this.tick, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    tick() {
        this.setState(state => ({
            remaining: state.remaining > 1 ? state.remaining - 1 : 0,
        }));
    }

    remainingMinutes() {
        const remainingMinutes = parseInt(this.state.remaining / 60, 10);

        if (remainingMinutes < 10) {
            return `0${remainingMinutes}`;
        } else {
            return remainingMinutes;
        }
    }

    remainingSeconds() {
        const remainingSeconds = this.state.remaining % 60;

        if (remainingSeconds < 10) {
            return `0${remainingSeconds}`;
        } else {
            return remainingSeconds;
        }
    }

    render() {
        return (
            <div id="mg-hour-counter" className="uk-hidden-medium">
                <strong>{this.remainingMinutes()}</strong>
                &nbsp;<span className="color-grey">:</span>&nbsp;
                <strong>{this.remainingSeconds()}</strong>
            </div>
        );
    }
}

Timer.propTypes = {
    limit: number.isRequired,
};

export default Timer;
