import { call, put, delay } from 'redux-saga/effects';
import Rollbar from 'config/rollbar';
import ErrorCodes from 'mangools-commons/lib/constants/ErrorCodes';
import Defaults from 'mangools-commons/lib/constants/Defaults';
import { has, isNil } from 'ramda';

import Strings from 'constants/Strings';

import {
    showNoConnectionMessage,
    showAccessDeniedMessage,
    showFailureMessage,
    // showUnauthorizedMessage,
} from 'actions/uiActions';
import { ErrorTypes } from '../constants/ErrorTypes';

export function* logError(tag = 'UnknownSaga', payload) {
    yield call([Rollbar, Rollbar.error], new Error(`[${tag}]`), payload);
}

export function* logInfo(label, payload = {}) {
    yield call([Rollbar, Rollbar.info], label, payload);
}

export function handleUncaught(generator, errorGenerator) {
    return function* handlingUncaught(...args) {
        let value = null;
        try {
            value = yield call(generator, ...args);
        } catch (e) {
            console.error(`Unhandled error in '${generator.name}'`, e); // eslint-disable-line no-console

            // Call error generator if provided
            if (errorGenerator) {
                yield call(errorGenerator);
            }

            yield call(logError, generator.name, `UNHANDLED: ${e.toString()}`);
        }

        return value;
    };
}

function retry(saga, action) {
    // eslint-disable-next-line func-names
    return function* () {
        yield delay(Defaults.CONNECTION_RETRY_DELAY);
        yield call(saga, action, true);
    };
}

/* eslint-disable func-names */
export function handleError({
    action,
    error,
    sagaLogTitle,
    retrying,
    errorAction,
    revertOptimisicUpdateAction,
    saga,
    failureMessage,
    handlers,
}) {
    switch (error.status) {
        case ErrorCodes.FETCH_ERROR: {
            if (has(ErrorCodes.FETCH_ERROR)(handlers)) {
                return handlers[ErrorCodes.FETCH_ERROR];
            } else if (retrying === true) {
                return function* () {
                    yield put(errorAction(error));
                    if (!isNil(revertOptimisicUpdateAction)) {
                        yield put(revertOptimisicUpdateAction());
                    }
                    yield put(showNoConnectionMessage());
                };
            } else {
                // Wait for CONNECTION_RETRY_DELAY and try again
                return retry(saga, action);
            }
        }
        case ErrorCodes.ACCESS_DENIED: {
            if (has(ErrorCodes.ACCESS_DENIED)(handlers)) {
                return handlers[ErrorCodes.ACCESS_DENIED];
            } else {
                return function* () {
                    yield put(errorAction(error));
                    if (!isNil(revertOptimisicUpdateAction)) {
                        yield put(revertOptimisicUpdateAction());
                    }
                    yield put(showAccessDeniedMessage());
                };
            }
        }
        case ErrorCodes.UNAUTHORIZED: {
            if (has(ErrorCodes.UNAUTHORIZED)(handlers)) {
                return handlers[ErrorCodes.UNAUTHORIZED];
            } else {
                return function* () {
                    yield put(errorAction(error));
                    if (!isNil(revertOptimisicUpdateAction)) {
                        yield put(revertOptimisicUpdateAction());
                    }
                };
            }
        }
        case ErrorCodes.TOO_MANY_REQUESTS: {
            if (has(ErrorCodes.TOO_MANY_REQUESTS)(handlers)) {
                return handlers[ErrorCodes.TOO_MANY_REQUESTS];
            } else if (error.type === ErrorTypes.REPEAT_REQUEST) {
                return function* () {
                    yield put(errorAction(error));
                    if (!isNil(revertOptimisicUpdateAction)) {
                        yield put(revertOptimisicUpdateAction());
                    }
                    yield put(
                        showFailureMessage({
                            details: Strings.messages.failure.too_many_requests_error,
                        }),
                    );
                };
            }

            return function* () {
                yield null;
            };
        }
        case ErrorCodes.SERVICE_UNAVAILABLE: {
            if (has(ErrorCodes.SERVICE_UNAVAILABLE)(handlers)) {
                return handlers[ErrorCodes.SERVICE_UNAVAILABLE];
            } else if (retrying === true) {
                return function* () {
                    yield put(errorAction(error));
                    if (!isNil(revertOptimisicUpdateAction)) {
                        yield put(revertOptimisicUpdateAction());
                    }
                    yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                    yield call(logError, sagaLogTitle, error);
                };
            } else {
                return retry(saga, action);
            }
        }
        case ErrorCodes.UNPROCESSABLE_ENTITY:
            if (has(ErrorCodes.UNPROCESSABLE_ENTITY)(handlers)) {
                return handlers[ErrorCodes.UNPROCESSABLE_ENTITY];
            } else {
                return function* () {
                    yield put(errorAction(error));
                    if (!isNil(revertOptimisicUpdateAction)) {
                        yield put(revertOptimisicUpdateAction());
                    }
                    yield put(showFailureMessage({ details: failureMessage }));
                    yield call(logError, sagaLogTitle, error);
                };
            }
        case ErrorCodes.INTERNAL_SERVER_ERROR:
        default: {
            if (has(ErrorCodes.INTERNAL_SERVER_ERROR)(handlers)) {
                return handlers[ErrorCodes.INTERNAL_SERVER_ERROR];
            } else if (retrying === true) {
                return function* () {
                    yield put(errorAction(error));
                    if (!isNil(revertOptimisicUpdateAction)) {
                        yield put(revertOptimisicUpdateAction());
                    }
                    yield put(showFailureMessage({ details: failureMessage }));
                    yield call(logError, sagaLogTitle, error);
                };
            } else {
                return retry(saga, action);
            }
        }
    }
}
/* eslint-enable func-names */
