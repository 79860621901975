import React from 'react';
import { bool, func, string } from 'prop-types';
import { connect } from 'react-redux';

import AccessDeniedMessage from 'components/messages/AccessDeniedMessage';
import DeleteConfirmationMessage from 'components/messages/DeleteConfirmationMessage';
import FailureMessage from 'components/messages/FailureMessage';
import LoggedOutMessage from 'components/messages/LoggedOutMessage';
import NeedToSignInMessage from 'components/messages/NeedToSignInMessage';
import NoConnectionMessage from 'components/messages/NoConnectionMessage';
import PricingMessage from 'components/messages/PricingMessage';
import UrlTypeInfoMessage from 'components/messages/UrlTypeInfoMessage';
import ShortcutsMessage from 'components/messages/ShortcutsMessage';
import CreateExportSuccessMessage from 'components/messages/CreateExportSuccessMessage';
import NotExistingDomainMessage from 'components/messages/NotExistingDomainMessage';

import {
    closeAccessDeniedMessage,
    closeDeleteConfirmationMessage,
    closeFailureMessage,
    closeLoggedOutMessage,
    closeNeedToSignInMessage,
    closeNoConnectionMessage,
    closePricingMessage,
    closeUrlTypeInfoMessage,
    confirmDeleteConfirmationMessage,
    closeShortcutsMessage,
    closeCreateExportSuccessMessage,
    closeNotExistingDomainMessage,
} from 'actions/uiActions';

import {
    accessDeniedMessageVisibilitySelector,
    deleteConfirmationMessageResourceNameSelector,
    deleteConfirmationMessageResourceTypeSelector,
    deleteConfirmationMessageVisibilitySelector,
    failureMessageDetailsSelector,
    failureMessageHeaderSelector,
    failureMessageVisibilitySelector,
    loggedOutMessageVisibilitySelector,
    needToSignInMessageVisibilitySelector,
    noConnectionMessageVisibilitySelector,
    pricingMessageVisibilitySelector,
    urlTypeInfoMessageVisibilitySelector,
    shortcutsMessageVisibilitySelector,
    createExportSuccessMessageVisibilitySelector,
    createExportSuccessMessageReadyInSelector,
    notExistingDomainMessageVisibilitySelector,
    notExistingDomainMessageDomainSelector,
} from 'selectors/uiSelectors';

import { userPlanTypeSelector, emailSelector } from 'selectors/userSelectors';

function MessageContainer(props) {
    return (
        <div>
            <AccessDeniedMessage visible={props.accessDeniedVisible} onClose={props.onCloseAccessDeniedMessage} />
            <NeedToSignInMessage visible={props.needToSignInVisible} onClose={props.onCloseNeedToSignInMessage} />
            <FailureMessage
                details={props.failureDetails}
                header={props.failureHeader}
                onClose={props.onCloseFailureMessage}
                visible={props.failureVisible}
            />
            <PricingMessage
                onClose={props.onClosePricingMessage}
                userPlanType={props.userPlanType}
                visible={props.pricingVisible}
            />
            <DeleteConfirmationMessage
                onClose={props.onCloseDeleteConfirmationMessage}
                onConfirm={props.onConfirmDeleteConfirmationMessage}
                resourceName={props.deleteConfirmationResourceName}
                resourceType={props.deleteConfirmationResourceType}
                visible={props.deleteConfirmationVisible}
            />
            <NoConnectionMessage onClose={props.onCloseNoConnectionMessage} visible={props.noConnectionVisible} />
            <UrlTypeInfoMessage onClose={props.onCloseUrlTypeInfoMessage} visible={props.urlTypeInfoMessageVisible} />
            <LoggedOutMessage onClose={props.onCloseLoggedOutMessage} visible={props.loggedOutMessageVisible} />
            <ShortcutsMessage onClose={props.onCloseShortcutsMessage} visible={props.shortcutsMessageVisible} />
            <CreateExportSuccessMessage
                onClose={props.onCloseCreateExportSuccessMessage}
                readyIn={props.createExportSuccessReadyIn}
                email={props.userEmail}
                visible={props.createExportSuccessVisible}
            />
            <NotExistingDomainMessage
                onClose={props.onCloseNotExistingDomainMessage}
                visible={props.notExistingDomainVisible}
                domain={props.notExistingDomainMessageDomain}
            />
        </div>
    );
}

MessageContainer.propTypes = {
    accessDeniedVisible: bool.isRequired,
    deleteConfirmationResourceName: string.isRequired,
    deleteConfirmationResourceType: string.isRequired,
    deleteConfirmationVisible: bool.isRequired,
    failureDetails: string,
    failureHeader: string,
    failureVisible: bool.isRequired,
    loggedOutMessageVisible: bool.isRequired,
    needToSignInVisible: bool.isRequired,
    noConnectionVisible: bool.isRequired,
    onCloseAccessDeniedMessage: func.isRequired,
    onCloseDeleteConfirmationMessage: func.isRequired,
    onCloseFailureMessage: func.isRequired,
    onCloseLoggedOutMessage: func.isRequired,
    onCloseNeedToSignInMessage: func.isRequired,
    onCloseNoConnectionMessage: func.isRequired,
    onClosePricingMessage: func.isRequired,
    onCloseUrlTypeInfoMessage: func.isRequired,
    onConfirmDeleteConfirmationMessage: func.isRequired,
    pricingVisible: bool.isRequired,
    urlTypeInfoMessageVisible: bool.isRequired,
    userPlanType: string.isRequired,
    onCloseShortcutsMessage: func.isRequired,
    shortcutsMessageVisible: bool.isRequired,
    onCloseCreateExportSuccessMessage: func.isRequired,
    createExportSuccessReadyIn: string,
    createExportSuccessVisible: bool.isRequired,
    userEmail: string,
    onCloseNotExistingDomainMessage: func.isRequired,
    notExistingDomainVisible: bool.isRequired,
    notExistingDomainMessageDomain: string,
};

const mapStateToProps = state => ({
    accessDeniedVisible: accessDeniedMessageVisibilitySelector(state),
    deleteConfirmationResourceName: deleteConfirmationMessageResourceNameSelector(state),
    deleteConfirmationResourceType: deleteConfirmationMessageResourceTypeSelector(state),
    deleteConfirmationVisible: deleteConfirmationMessageVisibilitySelector(state),
    failureDetails: failureMessageDetailsSelector(state),
    failureHeader: failureMessageHeaderSelector(state),
    failureVisible: failureMessageVisibilitySelector(state),
    loggedOutMessageVisible: loggedOutMessageVisibilitySelector(state),
    needToSignInVisible: needToSignInMessageVisibilitySelector(state),
    noConnectionVisible: noConnectionMessageVisibilitySelector(state),
    pricingVisible: pricingMessageVisibilitySelector(state),
    urlTypeInfoMessageVisible: urlTypeInfoMessageVisibilitySelector(state),
    userPlanType: userPlanTypeSelector(state),
    shortcutsMessageVisible: shortcutsMessageVisibilitySelector(state),
    createExportSuccessVisible: createExportSuccessMessageVisibilitySelector(state),
    createExportSuccessReadyIn: createExportSuccessMessageReadyInSelector(state),
    userEmail: emailSelector(state),
    notExistingDomainVisible: notExistingDomainMessageVisibilitySelector(state),
    notExistingDomainMessageDomain: notExistingDomainMessageDomainSelector(state),
});

const mapDispatchToProps = dispatch => ({
    onConfirmDeleteConfirmationMessage() {
        dispatch(confirmDeleteConfirmationMessage());
    },
    onCloseDeleteConfirmationMessage() {
        dispatch(closeDeleteConfirmationMessage());
    },
    onCloseAccessDeniedMessage() {
        dispatch(closeAccessDeniedMessage());
    },
    onCloseFailureMessage() {
        dispatch(closeFailureMessage());
    },
    onCloseLoggedOutMessage() {
        dispatch(closeLoggedOutMessage());
    },
    onCloseNeedToSignInMessage() {
        dispatch(closeNeedToSignInMessage());
    },
    onCloseNoConnectionMessage() {
        dispatch(closeNoConnectionMessage());
    },
    onClosePricingMessage() {
        dispatch(closePricingMessage());
    },
    onCloseUrlTypeInfoMessage() {
        dispatch(closeUrlTypeInfoMessage());
    },
    onCloseShortcutsMessage() {
        dispatch(closeShortcutsMessage());
    },
    onCloseCreateExportSuccessMessage() {
        dispatch(closeCreateExportSuccessMessage());
    },
    onCloseNotExistingDomainMessage() {
        dispatch(closeNotExistingDomainMessage());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageContainer);
