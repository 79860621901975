import update from 'immutability-helper';
import { omit } from 'ramda';

import ActionTypes from 'constants/ActionTypes';

const initialState = {
    accessDenied: {
        visibility: false,
    },
    deleteConfirmation: {
        resourceName: '',
        resourceType: '',
        visibility: false,
    },
    failure: {
        visibility: false,
        details: null,
        header: null,
    },
    loggedOut: {
        visibility: false,
    },
    needToSignIn: {
        visibility: false,
    },
    noConnection: {
        visibility: false,
    },
    pricing: {
        visibility: false,
    },
    topLevel: {
        announcement: {
            visibility: true,
        },
    },
    urlTypeInfo: {
        visibility: false,
    },
    shortcuts: {
        visibility: false,
    },
    createExportSuccess: {
        visibility: false,
        readyIn: null,
    },
    notExistingDomain: {
        visibility: false,
        domain: null,
    },
};

const messagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UI_MESSAGES_DELETE_CONFIRMATION_SHOW: {
            return update(state, {
                deleteConfirmation: {
                    visibility: {
                        $set: true,
                    },
                    resourceName: {
                        $set: action.payload.resourceName,
                    },
                    resourceType: {
                        $set: action.payload.resourceType,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_DELETE_CONFIRMATION_CONFIRM:
        case ActionTypes.UI_MESSAGES_DELETE_CONFIRMATION_CLOSE: {
            return update(state, {
                deleteConfirmation: {
                    visibility: {
                        $set: false,
                    },
                    resourceName: {
                        $set: initialState.deleteConfirmation.resourceName,
                    },
                    resourceType: {
                        $set: initialState.deleteConfirmation.resourceType,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_FAILURE_SHOW: {
            return update(state, {
                failure: {
                    visibility: {
                        $set: true,
                    },
                    details: {
                        $set: action.payload.details,
                    },
                    header: {
                        $set: action.payload.header,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_FAILURE_CLOSE: {
            return update(state, {
                failure: {
                    visibility: {
                        $set: false,
                    },
                    details: {
                        $set: null,
                    },
                    header: {
                        $set: null,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_NEED_TO_SIGN_IN_SHOW: {
            return update(state, {
                needToSignIn: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_NEED_TO_SIGN_IN_CLOSE: {
            return update(state, {
                needToSignIn: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_PRICING_SHOW: {
            return update(state, {
                pricing: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_PRICING_CLOSE: {
            return update(state, {
                pricing: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_ACCESS_DENIED_SHOW: {
            return update(state, {
                accessDenied: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_ACCESS_DENIED_CLOSE: {
            return update(state, {
                accessDenied: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_TOP_LEVEL_ANNOUNCEMENT_CLOSE: {
            return update(state, {
                topLevel: {
                    announcement: {
                        visibility: {
                            $set: false,
                        },
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_NO_CONNECTION_SHOW: {
            return update(state, {
                noConnection: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_NO_CONNECTION_CLOSE: {
            return update(state, {
                noConnection: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_URL_TYPE_INFO_SHOW: {
            return update(state, {
                urlTypeInfo: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_URL_TYPE_INFO_CLOSE: {
            return update(state, {
                urlTypeInfo: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_LOGGED_OUT_SHOW: {
            return update(state, {
                loggedOut: {
                    visibility: {
                        $set: true,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_LOGGED_OUT_CLOSE: {
            return update(state, {
                loggedOut: {
                    visibility: {
                        $set: false,
                    },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_SHORTCUTS_SHOW: {
            return update(state, {
                shortcuts: { visibility: { $set: true } },
            });
        }
        case ActionTypes.UI_MESSAGES_SHORTCUTS_CLOSE: {
            return update(state, {
                shortcuts: { visibility: { $set: false } },
            });
        }
        case ActionTypes.UI_MESSAGES_CREATE_EXPORT_SUCCESS_SHOW: {
            return update(state, {
                createExportSuccess: {
                    visibility: { $set: true },
                    readyIn: { $set: action.payload },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_CREATE_EXPORT_SUCCESS_CLOSE: {
            return update(state, {
                createExportSuccess: {
                    visibility: { $set: false },
                    readyIn: { $set: null },
                },
            });
        }
        case ActionTypes.UI_MESSAGES_NOT_EXISTING_DOMAIN_SHOW:
            return update(state, {
                notExistingDomain: {
                    visibility: { $set: true },
                    domain: { $set: action.payload },
                },
            });
        case ActionTypes.UI_MESSAGES_NOT_EXISTING_DOMAIN_CLOSE:
            return update(state, {
                notExistingDomain: {
                    visibility: { $set: false },
                    domain: { $set: null },
                },
            });
        case ActionTypes.UI_ALL_CLOSE: {
            return update(state, { $merge: omit('topLevel', initialState) });
        }
        default: {
            return state;
        }
    }
};

export default messagesReducer;
