import React from 'react';

import { LinkRefSources } from 'mangools-commons/lib/constants/LinkRefSources';
import { MangoolsNavBar } from 'mangools-react-components';
import DOMService from 'mangools-commons/lib/services/DOMService';

import Preloader from 'components/other/Preloader';
import Strings from 'constants/Strings';
import mnglsImageWhite from 'images/mangools-logo-full-white.svg';
import DarkModeToggle from 'components/other/DarkModeToggle';

import config from 'appConfig';

function FullAppPreloader() {
    return (
        <>
            <header className="mg-header">
                <MangoolsNavBar
                    appName={LinkRefSources.LINKMINER_APP}
                    rightEl={(
                        <div className="uk-flex uk-flex-middle mg-margin-r-30" style={{ opacity: 0.2 }}>
                            <div className="uk-flex uk-flex-middle">
                                {DOMService.isMobile() && (
                                    <ul className="mg-nav uk-visible-small">
                                        <li>
                                            <DarkModeToggle />
                                        </li>
                                    </ul>
                                )}
                                <div className="mg-preloader-block mg-margin-l-5" style={{ width: '30px' }} />
                            </div>
                        </div>
                    )}
                    onShowAppPanel={() => null}
                    logoDarkSrc={mnglsImageWhite}
                    logoLightSrc={mnglsImageWhite}
                    env={config.APP_ENV}
                />
                <div className="mg-header-app uk-flex uk-flex-middle uk-flex-space-between uk-hidden-small">
                    <div className="uk-flex" style={{ opacity: 0.2 }}>
                        <div className="mg-padding-0-15">
                            <div className="mg-preloader-block" style={{ width: '40px' }} />
                        </div>
                        <div className="mg-padding-0-15">
                            <div className="mg-preloader-block" style={{ width: '40px' }} />
                        </div>
                        <div className="mg-padding-0-15">
                            <div className="mg-preloader-block" style={{ width: '55px' }} />
                        </div>
                        <div className="mg-padding-0-15">
                            <div className="mg-preloader-block" style={{ width: '40px' }} />
                        </div>
                    </div>
                    <div className="uk-flex" style={{ opacity: 0.2 }}>
                        <div className="mg-padding-0-15">
                            <div className="mg-preloader-block" style={{ width: '20px' }} />
                        </div>
                        <div className="mg-padding-0-15">
                            <div className="mg-preloader-block" style={{ width: '20px' }} />
                        </div>
                        <div className="mg-padding-0-15">
                            <div className="mg-preloader-block" style={{ width: '30px' }} />
                        </div>
                    </div>
                </div>
            </header>
            <div className="uk-position-cover uk-flex uk-flex-middle uk-flex-center">
                <Preloader text={Strings.components.common.loading} />
            </div>
        </>
    );
}

export default FullAppPreloader;
