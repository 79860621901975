import ActionTypes from 'constants/ActionTypes';

/**
 * Redux action creators for data (links, favourite lists...) fetching and creating.
 */

export const requestedResultsAction = () => ({
    type: ActionTypes.DATA_RESULTS_REQUESTED,
});

export const fetchingResultsAction = () => ({
    type: ActionTypes.DATA_RESULTS_FETCHING,
});

export const receivedResultsAction = payload => ({
    type: ActionTypes.DATA_RESULTS_RECEIVED,
    error: false,
    payload,
});

export const errorResultsAction = payload => ({
    type: ActionTypes.DATA_RESULTS_ERROR,
    error: true,
    payload,
});

export const requestedMoreResultsAction = () => ({
    type: ActionTypes.DATA_MORE_RESULTS_REQUESTED,
});

export const fetchingMoreResultsAction = () => ({
    type: ActionTypes.DATA_MORE_RESULTS_FETCHING,
});

export const receivedMoreResultsAction = payload => ({
    type: ActionTypes.DATA_MORE_RESULTS_RECEIVED,
    error: false,
    payload,
});

export const errorMoreResultsAction = payload => ({
    type: ActionTypes.DATA_MORE_RESULTS_ERROR,
    error: true,
    payload,
});

export const skippedMoreResultsAction = () => ({
    type: ActionTypes.DATA_MORE_RESULTS_SKIPPED,
});

export const requestedFavoriteListResultsAction = listId => ({
    type: ActionTypes.DATA_RESULTS_FAVORITE_LIST_REQUESTED,
    error: false,
    payload: listId,
});

export const receivedFavoriteListResultsAction = payload => ({
    type: ActionTypes.DATA_RESULTS_FAVORITE_LIST_RECEIVED,
    error: false,
    payload,
});

export const requestedFavoriteListsAction = () => ({
    type: ActionTypes.DATA_FAVORITE_LISTS_REQUESTED,
});

export const fetchingFavoriteListsAction = () => ({
    type: ActionTypes.DATA_FAVORITE_LISTS_FETCHING,
});

export const receivedFavoriteListsAction = payload => ({
    type: ActionTypes.DATA_FAVORITE_LISTS_RECEIVED,
    error: false,
    payload,
});

export const errorFavoriteListsAction = payload => ({
    type: ActionTypes.DATA_FAVORITE_LISTS_ERROR,
    error: true,
    payload,
});

export const requestedFavoriteListsDeleteAction = ({ listId, domain }) => ({
    type: ActionTypes.DATA_FAVORITE_LISTS_DELETE_REQUESTED,
    error: false,
    payload: {
        listId,
        domain,
    },
});

export const fetchingFavoriteListsDeleteAction = () => ({
    type: ActionTypes.DATA_FAVORITE_LISTS_DELETE_FETCHING,
});

export const receivedFavoriteListsDeleteAction = listId => ({
    type: ActionTypes.DATA_FAVORITE_LISTS_DELETE_RECEIVED,
    error: false,
    payload: listId,
});

export const errorFavoriteListsDeleteAction = payload => ({
    type: ActionTypes.DATA_FAVORITE_LISTS_DELETE_ERROR,
    error: true,
    payload,
});

export const cancelledFavoriteListsDeleteAction = payload => ({
    type: ActionTypes.DATA_FAVORITE_LISTS_DELETE_CANCELLED,
    error: false,
    payload,
});

export const skippedFavoriteListsAction = () => ({
    type: ActionTypes.DATA_FAVORITE_LISTS_SKIPPED,
});

export const requestedSetFavoriteFlagForLinkAction = ({ linkId, favorite }) => ({
    type: ActionTypes.DATA_RESULTS_FAVORITE_FLAG_SET_REQUESTED,
    error: false,
    payload: {
        linkId,
        favorite,
    },
});

export const fetchingSetFavoriteFlagForLinkAction = linkId => ({
    type: ActionTypes.DATA_RESULTS_FAVORITE_FLAG_SET_FETCHING,
    error: false,
    payload: linkId,
});

export const receivedSetFavoriteFlagForLinkAction = payload => ({
    type: ActionTypes.DATA_RESULTS_FAVORITE_FLAG_SET_RECEIVED,
    error: false,
    payload,
});

export const errorSetFavoriteFlagForLinkAction = payload => ({
    type: ActionTypes.DATA_RESULTS_FAVORITE_FLAG_SET_ERROR,
    error: true,
    payload,
});

export const requestedUrlDataAction = () => ({
    type: ActionTypes.DATA_URL_DATA_REQUESTED,
});

export const fetchingUrlDataAction = () => ({
    type: ActionTypes.DATA_URL_DATA_FETCHING,
});

export const receivedUrlDataAction = payload => ({
    type: ActionTypes.DATA_URL_DATA_RECEIVED,
    error: false,
    payload,
});

export const errorUrlDataAction = payload => ({
    type: ActionTypes.DATA_URL_DATA_ERROR,
    error: true,
    payload,
});

export const rehydrationComplete = () => ({
    type: ActionTypes.DATA_REHYDRATATION_COMPLETE,
});

export const requestedExportAction = ({ includeMetrics }) => ({
    type: ActionTypes.DATA_RESULTS_EXPORT_REQUESTED,
    error: false,
    payload: { includeMetrics },
});

export const errorExportAction = payload => ({
    type: ActionTypes.DATA_RESULTS_EXPORT_ERROR,
    error: true,
    payload,
});

export const receivedExportAction = payload => ({
    type: ActionTypes.DATA_RESULTS_EXPORT_RECEIVED,
    error: false,
    payload,
});

export const requestedHistoryAction = () => ({
    type: ActionTypes.DATA_HISTORY_REQUESTED,
});

export const fetchingHistoryAction = () => ({
    type: ActionTypes.DATA_HISTORY_FETCHING,
});

export const receivedHistoryAction = payload => ({
    type: ActionTypes.DATA_HISTORY_RECEIVED,
    error: false,
    payload,
});

export const errorHistoryAction = payload => ({
    type: ActionTypes.DATA_HISTORY_ERROR,
    error: true,
    payload,
});

export const skippedHistoryAction = () => ({
    type: ActionTypes.DATA_HISTORY_SKIPPED,
});

export const fetchingAnnouncementsAction = () => ({
    type: ActionTypes.DATA_ANNOUNCEMENTS_FETCHING,
});

export const receivedAnnouncementsAction = payload => ({
    type: ActionTypes.DATA_ANNOUNCEMENTS_RECEIVED,
    error: false,
    payload,
});

export const errorAnnouncementsAction = payload => ({
    type: ActionTypes.DATA_ANNOUNCEMENTS_ERROR,
    error: true,
    payload,
});

export const errorDeleteHistoryAction = payload => ({
    type: ActionTypes.DATA_HISTORY_DELETE_ERROR,
    error: true,
    payload,
});

export const receivedDeleteHistoryAction = () => ({
    type: ActionTypes.DATA_HISTORY_DELETE_RECEIVED,
});

export const requestedDeleteHistoryAction = () => ({
    type: ActionTypes.DATA_HISTORY_DELETE_REQUESTED,
});

export const requestedBacklinkExportSuggestionsAction = (url, cloneType = null) => ({
    type: ActionTypes.DATA_BACKLINK_EXPORT_SUGGESTIONS_REQUESTED,
    payload: { url, cloneType },
});

export const receivedBacklinkExportSuggestionsAction = suggestions => ({
    type: ActionTypes.DATA_BACKLINK_EXPORT_SUGGESTIONS_RECEIVED,
    payload: suggestions,
});

export const errorBacklinkExportSuggestionsAction = payload => ({
    type: ActionTypes.DATA_BACKLINK_EXPORT_SUGGESTIONS_ERROR,
    error: true,
    payload,
});

export const resetBacklinkExportSuggestionsAction = () => ({
    type: ActionTypes.DATA_BACKLINK_EXPORT_SUGGESTIONS_RESET,
});

export const fetchingBacklinkExportsAction = () => ({
    type: ActionTypes.DATA_BACKLINK_EXPORTS_FETCHING,
});

export const requestedBacklinkExportsAction = (isInterval = false) => ({
    type: ActionTypes.DATA_BACKLINK_EXPORTS_REQUESTED,
    payload: isInterval,
});

export const startRequestingBacklinkExportsAction = () => ({
    type: ActionTypes.DATA_BACKLINK_EXPORTS_REQUESTS_START,
});

export const stopRequestingBacklinkExportsAction = () => ({
    type: ActionTypes.DATA_BACKLINK_EXPORTS_REQUESTS_STOP,
});

export const receivedBacklinkExportsAction = backlinkExports => ({
    type: ActionTypes.DATA_BACKLINK_EXPORTS_RECEIVED,
    payload: backlinkExports,
});

export const errorBacklinkExportsAction = payload => ({
    type: ActionTypes.DATA_BACKLINK_EXPORTS_ERROR,
    error: true,
    payload,
});

export const fetchingNewBacklinkExportAction = () => ({
    type: ActionTypes.DATA_NEW_BACKLINK_EXPORT_FETCHING,
});

export const requestedNewBacklinkExportAction = ({ cost, url }) => ({
    type: ActionTypes.DATA_NEW_BACKLINK_EXPORT_REQUESTED,
    payload: {
        cost,
        url,
    },
});

export const receivedNewBacklinkExportAction = payload => ({
    type: ActionTypes.DATA_NEW_BACKLINK_EXPORT_RECEIVED,
    payload,
});

export const errorNewBacklinkExportAction = payload => ({
    type: ActionTypes.DATA_NEW_BACKLINK_EXPORT_ERROR,
    error: true,
    payload,
});
