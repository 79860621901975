import FilterService from 'services/FilterService';

import { dashboardFilterKeys, exportFilterKeys } from 'constants/FilterKeys';
import LinkRelTypes from 'constants/LinkRelTypes';
import LinkStatusTypes from 'constants/LinkStatusTypes';

export const dashboardFilterSettings = (({
    ANCHOR_FILTER,
    HREF_FILTER,
    MAX_TOP_RANK,
    MAX_CITATION_FLOW,
    MAX_EXTERNAL_LINKS,
    MAX_LINK_STRENGTH,
    MAX_TRUST_FLOW,
    MIN_TOP_RANK,
    MIN_CITATION_FLOW,
    MIN_EXTERNAL_LINKS,
    MIN_LINK_STRENGTH,
    MIN_TRUST_FLOW,
    URL_FILTER,
}) => {
    return {
        [ANCHOR_FILTER]: {
            initialValue: '',
            filterFormatter: FilterService.formatValue,
            submitFormatter: FilterService.trim,
        },
        [HREF_FILTER]: {
            initialValue: '',
            filterFormatter: FilterService.formatValue,
            submitFormatter: FilterService.trim,
        },
        [MAX_TOP_RANK]: {
            initialValue: null,
            filterFormatter: FilterService.formatValue,
            submitFormatter: FilterService.parseMaxToInt,
        },
        [MAX_CITATION_FLOW]: {
            initialValue: null,
            filterFormatter: FilterService.formatValue,
            submitFormatter: FilterService.parseMaxToInt,
        },
        [MAX_EXTERNAL_LINKS]: {
            initialValue: null,
            filterFormatter: FilterService.formatValue,
            submitFormatter: FilterService.parseMaxToInt,
        },
        [MAX_LINK_STRENGTH]: {
            initialValue: null,
            filterFormatter: FilterService.formatValue,
            submitFormatter: FilterService.parseMaxToInt,
        },
        [MAX_TRUST_FLOW]: {
            initialValue: null,
            filterFormatter: FilterService.formatValue,
            submitFormatter: FilterService.parseMaxToInt,
        },
        [MIN_TOP_RANK]: {
            initialValue: 0,
            filterFormatter: FilterService.formatValue,
            submitFormatter: FilterService.parseMinToInt,
        },
        [MIN_CITATION_FLOW]: {
            initialValue: 0,
            filterFormatter: FilterService.formatValue,
            submitFormatter: FilterService.parseMinToInt,
        },
        [MIN_EXTERNAL_LINKS]: {
            initialValue: 0,
            filterFormatter: FilterService.formatValue,
            submitFormatter: FilterService.parseMinToInt,
        },
        [MIN_LINK_STRENGTH]: {
            initialValue: 0,
            filterFormatter: FilterService.formatValue,
            submitFormatter: FilterService.parseMinToInt,
        },
        [MIN_TRUST_FLOW]: {
            initialValue: 0,
            filterFormatter: FilterService.formatValue,
            submitFormatter: FilterService.parseMinToInt,
        },
        [URL_FILTER]: {
            initialValue: '',
            filterFormatter: FilterService.formatValue,
            submitFormatter: FilterService.trim,
        },
    };
})(dashboardFilterKeys);

export const exportFilterSettings = (({
    LINKS_PER_DOMAIN,
    TARGET_ANCHOR_TEXT,
    TARGET_HREF_TEXT,
    MIN_TOP_RANK,
    MAX_TOP_RANK,
    MIN_CITATION_FLOW,
    MAX_CITATION_FLOW,
    MIN_TRUST_FLOW,
    MAX_TRUST_FLOW,
    MIN_LINK_STRENGTH,
    MAX_LINK_STRENGTH,
    NO_FOLLOW,
    LIVE_LINKS,
}) => {
    return {
        [LINKS_PER_DOMAIN]: {
            initialValue: 0,
            filterFormatter: FilterService.formatValue,
            submitFormatter: FilterService.parseMinToInt,
        },
        [TARGET_ANCHOR_TEXT]: {
            initialValue: '',
            filterFormatter: FilterService.formatValue,
            submitFormatter: FilterService.trim,
        },
        [TARGET_HREF_TEXT]: {
            initialValue: '',
            filterFormatter: FilterService.formatValue,
            submitFormatter: FilterService.trim,
        },
        [MAX_TOP_RANK]: {
            initialValue: null,
            filterFormatter: FilterService.formatValue,
            submitFormatter: FilterService.parseMaxToInt,
        },
        [MIN_TOP_RANK]: {
            initialValue: 0,
            filterFormatter: FilterService.formatValue,
            submitFormatter: FilterService.parseMinToInt,
        },
        [MAX_CITATION_FLOW]: {
            initialValue: null,
            filterFormatter: FilterService.formatValue,
            submitFormatter: FilterService.parseMaxToInt,
        },
        [MIN_CITATION_FLOW]: {
            initialValue: 0,
            filterFormatter: FilterService.formatValue,
            submitFormatter: FilterService.parseMinToInt,
        },
        [MAX_TRUST_FLOW]: {
            initialValue: null,
            filterFormatter: FilterService.formatValue,
            submitFormatter: FilterService.parseMaxToInt,
        },
        [MIN_TRUST_FLOW]: {
            initialValue: 0,
            filterFormatter: FilterService.formatValue,
            submitFormatter: FilterService.parseMinToInt,
        },
        [MAX_LINK_STRENGTH]: {
            initialValue: null,
            filterFormatter: FilterService.formatValue,
            submitFormatter: FilterService.parseMaxToInt,
        },
        [MIN_LINK_STRENGTH]: {
            initialValue: 0,
            filterFormatter: FilterService.formatValue,
            submitFormatter: FilterService.parseMinToInt,
        },
        [NO_FOLLOW]: {
            initialValue: null,
            filterFormatter: value => {
                switch (value) {
                    case null:
                        return LinkRelTypes.ALL;
                    case true:
                        return LinkRelTypes.NO_FOLLOW;
                    case false:
                        return LinkRelTypes.DO_FOLLOW;
                    default:
                        return LinkRelTypes.ALL;
                }
            },
            submitFormatter: value => {
                switch (value) {
                    case LinkRelTypes.ALL:
                        return null;
                    case LinkRelTypes.NO_FOLLOW:
                        return true;
                    case LinkRelTypes.DO_FOLLOW:
                        return false;
                    default:
                        return null;
                }
            },
        },
        [LIVE_LINKS]: {
            initialValue: null,
            filterFormatter: value => {
                switch (value) {
                    case null:
                        return LinkStatusTypes.ALL;
                    case true:
                        return LinkStatusTypes.ACTIVE;
                    case false:
                        return LinkStatusTypes.DELETED;
                    default:
                        return LinkStatusTypes.ALL;
                }
            },
            submitFormatter: value => {
                switch (value) {
                    case LinkStatusTypes.ALL:
                        return null;
                    case LinkStatusTypes.ACTIVE:
                        return true;
                    case LinkStatusTypes.DELETED:
                        return false;
                    default:
                        return null;
                }
            },
        },
    };
})(exportFilterKeys);

export const exportFilterLabels = (({
    LINKS_PER_DOMAIN,
    TARGET_ANCHOR_TEXT,
    TARGET_HREF_TEXT,
    MIN_TOP_RANK,
    MAX_TOP_RANK,
    MIN_CITATION_FLOW,
    MAX_CITATION_FLOW,
    MIN_TRUST_FLOW,
    MAX_TRUST_FLOW,
    MIN_LINK_STRENGTH,
    MAX_LINK_STRENGTH,
    NO_FOLLOW,
    LIVE_LINKS,
}) => {
    return {
        [LINKS_PER_DOMAIN]: 'Links per domain',
        [TARGET_ANCHOR_TEXT]: 'Target anchor text',
        [TARGET_HREF_TEXT]: 'Target href text',
        [MIN_TOP_RANK]: 'Top rank',
        [MAX_TOP_RANK]: 'Top rank',
        [MIN_CITATION_FLOW]: 'Citation flow',
        [MAX_CITATION_FLOW]: 'Citation flow',
        [MIN_TRUST_FLOW]: 'Trust flow',
        [MAX_TRUST_FLOW]: 'Trust flow',
        [MIN_LINK_STRENGTH]: 'Link strength',
        [MAX_LINK_STRENGTH]: 'Link strength',
        [NO_FOLLOW]: 'Link rel',
        [LIVE_LINKS]: 'Link status',
    };
})(exportFilterKeys);
