import React from 'react';
import { bool, element, func, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import Alert from 'react-s-alert';
import ShortcutService from 'mangools-commons/lib/services/ShortcutService';
import { GlobalHotKeys } from 'react-hotkeys';

import Overlay from 'components/layout/Overlay';
import MessageContainer from 'components/messages/MessageContainer';
import ModalContainer from 'components/modals/ModalContainer';
import NavbarContainer from 'components/layout/navbar/NavbarContainer';
import PanelContainer from 'components/panels/PanelContainer';
import TopLevelMessageContainer from 'components/messages/topLevel/TopLevelMessageContainer';

import { closeAll, closeShortcutsMessage, showShortcutsMessage } from 'actions/uiActions';

import {
    mobileOverlayVisibilitySelector,
    onlineStatusSelector,
    overlayVisibilitySelector,
    shortcutsMessageVisibilitySelector,
    areShortcutsDisabledSelector,
} from 'selectors/uiSelectors';

import { newUserMessageVisibilitySelector } from 'selectors/userSelectors';

import { announcementMesageVisibilitySelector } from 'selectors/dataSelectors';

import shortcuts, { SHOW_SHORTCUTS_TOGGLE, CLOSE_ALL } from 'constants/Shortcuts';

/**
 * Application layout component.
 */
const LayoutContainer = props => {
    const appClasses = classnames({
        'is-offline': props.onlineStatus === false,
        'linkminer-app': true,
        'is-messaged': props.topLevelMessageVisible,
    });

    const handleToggleShortucts = () => {
        if (props.shortcutsMessageVisible) {
            props.onCloseShortcutsMessage();
        } else {
            props.onShowShortcutsMessage();
        }
    };

    const renderHotkeys = areShortcutsDisabled => {
        const { keyMap, handlers } = ShortcutService.getShortcutMapAndHandlers({
            handlers: {
                [SHOW_SHORTCUTS_TOGGLE]: { func: handleToggleShortucts },
                [CLOSE_ALL]: { func: props.onCloseAll, preventDisable: true },
            },
            areShortcutsDisabled,
            shortcuts,
        });

        return <GlobalHotKeys allowChanges keyMap={keyMap} handlers={handlers} />;
    };

    return (
        <>
            {renderHotkeys(props.areShortcutsDisabled)}
            <div className={appClasses}>
                <TopLevelMessageContainer />
                <NavbarContainer currentRoute={props.location.pathname} />
                <div>{props.children}</div>
                <ModalContainer currentRoute={props.location.pathname} />
                <MessageContainer />
                <PanelContainer />
                <Overlay mobile={false} onClick={props.onCloseAll} visibility={props.overlayVisibility} />
                <Overlay mobile onClick={props.onCloseAll} visibility={props.mobileOverlayVisibility} />
                <div className="uk-hidden-small">
                    <Alert effect="slide" offset={50} position="top-right" stack={{ limit: 3 }} timeout={3500} />
                </div>
            </div>
        </>
    );
};

LayoutContainer.propTypes = {
    children: element.isRequired,
    location: shape({ pathname: string }).isRequired,
    mobileOverlayVisibility: bool.isRequired,
    onCloseAll: func.isRequired,
    onlineStatus: bool.isRequired,
    overlayVisibility: bool.isRequired,
    topLevelMessageVisible: bool.isRequired,
    onShowShortcutsMessage: func.isRequired,
    onCloseShortcutsMessage: func.isRequired,
    shortcutsMessageVisible: bool.isRequired,
    areShortcutsDisabled: bool.isRequired,
};

const mapStateToProps = state => ({
    mobileOverlayVisibility: mobileOverlayVisibilitySelector(state),
    onlineStatus: onlineStatusSelector(state),
    overlayVisibility: overlayVisibilitySelector(state),
    topLevelMessageVisible: newUserMessageVisibilitySelector(state) || announcementMesageVisibilitySelector(state),
    shortcutsMessageVisible: shortcutsMessageVisibilitySelector(state),
    areShortcutsDisabled: areShortcutsDisabledSelector(state),
});

const mapDispatchToProps = dispatch => ({
    onCloseAll() {
        dispatch(closeAll());
    },
    onShowShortcutsMessage() {
        dispatch(showShortcutsMessage());
    },
    onCloseShortcutsMessage() {
        dispatch(closeShortcutsMessage());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutContainer);
