import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

// import defaultsReducer from 'reducers/ui/defaultsReducer';
import announcementsReducer from 'reducers/data/announcementsReducer';
import dashboardReducer from 'reducers/ui/dashboardReducer';
import dropdownsReducer from 'reducers/ui/dropdownsReducer';
import favoritesReducer from 'reducers/data/favoritesReducer';
import filterReducer from 'reducers/ui/filterReducer';
import historyReducer from 'reducers/data/historyReducer';
import messagesReducer from 'reducers/ui/messagesReducer';
import miscReducer from 'reducers/ui/miscReducer';
import modalsReducer from 'reducers/ui/modalsReducer';
import panelsReducer from 'reducers/ui/panelsReducer';
import paramsReducer from 'reducers/paramsReducer';
import resultsReducer from 'reducers/data/resultsReducer';
import urlDataReducer from 'reducers/data/urlDataReducer';
import userReducer from 'reducers/userReducer';
import backlinkExportReducer from 'reducers/data/backlinkExportReducer';
import createExportReducer from 'reducers/ui/createExportReducer';

const rootReducer = combineReducers({
    data: combineReducers({
        announcements: announcementsReducer,
        favorites: favoritesReducer,
        history: historyReducer,
        results: resultsReducer,
        url: urlDataReducer,
        backlinkExport: backlinkExportReducer,
    }),
    params: paramsReducer,
    routing: routerReducer,
    ui: combineReducers({
        dashboard: dashboardReducer,
        dropdowns: dropdownsReducer,
        messages: messagesReducer,
        misc: miscReducer,
        modals: modalsReducer,
        panels: panelsReducer,
        filter: filterReducer,
        createExport: createExportReducer,
    }),
    user: userReducer,
});

export default rootReducer;
