export default {
    ACTIVE: 'ACTIVE',
    ALL: 'ALL',
    DELETED: 'DELETED',
};

export const ALL = {
    id: 0,
    label: 'Active/Deleted',
    value: 'ALL',
};

export const ALL_ALT = {
    id: 0,
    label: 'All',
    value: 'ALL',
};

export const ACTIVE = {
    id: 1,
    label: 'Active',
    value: 'ACTIVE',
};

export const DELETED = {
    id: 2,
    label: 'Deleted',
    value: 'DELETED',
};

export const LINK_STATUS_LIST = [ALL, ACTIVE, DELETED];
export const LINK_STATUS_LIST_ALT = [ALL_ALT, ACTIVE, DELETED];
