import React from 'react';
import { bool, func } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Urls from 'mangools-commons/lib/constants/Urls';

import MessageHolder from 'components/messages/MessageHolder';
import UrlTypes from 'constants/UrlTypes';

function UrlTypeInfoMessage(props) {
    if (props.visible) {
        return (
            <MessageHolder onClose={props.onClose} classNames="is-large">
                <div className="mg-modal-content uk-clearfix">
                    <h3 className="font-24 uk-text-bold mg-margin-t-30">Supported Input Types</h3>
                    <div className="mg-margin-t-60 uk-text-left uk-grid">
                        <div className="uk-width-medium-3-10 uk-text-right uk-text-left-small">
                            <strong>
                                example.com
                                <br />
                                mangools.com
                            </strong>
                        </div>
                        <div className="uk-visible-small mg-margin-b-15" />
                        <div className="uk-width-medium-2-10 uk-text-right uk-text-left-small">
                            <strong className="mg-label is-yellow font-12 mg-margin-b-15">
                                {UrlTypes.ROOT_DOMAIN}
                            </strong>
                        </div>
                        <div className="uk-width-medium-5-10">
                            <p className="color-grey font-14">
                                The main and&nbsp;
                                <strong className="color-black">recommended form of input</strong>. Results will be all
                                backlinks for the whole domain. Note that <em>http</em>, <em>https</em> and <em>www</em>
                                &nbsp; are not included.
                            </p>
                        </div>
                    </div>
                    <div className="uk-grid-divider" />
                    <div className="uk-grid uk-text-left">
                        <div className="uk-width-medium-3-10 uk-text-right uk-text-left-small">
                            <strong>
                                subdomain.example.com
                                <br />
                                www.mangools.com
                            </strong>
                        </div>
                        <div className="uk-visible-small mg-margin-b-15" />
                        <div className="uk-width-medium-2-10 uk-text-right uk-text-left-small">
                            <strong className="mg-label is-yellow font-12 mg-margin-b-15">{UrlTypes.SUBDOMAIN}</strong>
                        </div>
                        <div className="uk-width-medium-5-10">
                            <p className="color-grey font-14">
                                Use this form to find backlinks linking via <em>www</em> or any other subdomain.
                            </p>
                        </div>
                    </div>
                    <div className="uk-grid-divider" />
                    <div className="uk-grid uk-text-left">
                        <div className="uk-width-medium-3-10 uk-text-right uk-text-left-small">
                            <strong>
                                http://example.com
                                <br />
                                https://mangools.com/faq
                            </strong>
                        </div>
                        <div className="uk-visible-small mg-margin-b-15" />
                        <div className="uk-width-medium-2-10 uk-text-right uk-text-left-small">
                            <strong className="mg-label is-yellow font-12 mg-margin-b-15">{UrlTypes.EXACT_URL}</strong>
                        </div>
                        <div className="uk-visible-small mg-margin-b-15" />
                        <div className="uk-width-medium-5-10">
                            <p className="color-grey font-14">
                                Note that <em>http</em> and <em>https</em> versions are different URLs and will have
                                different backlinks.&nbsp; Non-www and www versions are also different URLs.
                            </p>
                        </div>
                    </div>
                    <div className="uk-grid-divider" />
                    <div className="uk-grid uk-text-left">
                        <div className="uk-width-medium-3-10 uk-text-right uk-text-left-small">
                            <strong>
                                example.com/subpath/*
                                <br />
                                mangools.com/blog/seopedia/*
                            </strong>
                        </div>
                        <div className="uk-visible-small mg-margin-b-15" />
                        <div className="uk-width-medium-2-10 uk-text-right uk-text-left-small">
                            <strong className="mg-label is-yellow font-12 mg-margin-b-15">{UrlTypes.PATH_URL}</strong>
                        </div>
                        <div className="uk-width-medium-5-10">
                            <p className="color-grey font-14">
                                Use asterisk as a wild character. For example, get backlinks for any blog post page
                                located on <i>/blog</i> subpath.
                            </p>
                        </div>
                    </div>
                    <div className="uk-grid-divider" />
                    <a
                        className="mg-btn is-blue is-small uk-text-bold "
                        href={Urls.LINKMINER_GUIDE_URL}
                        rel="noppener"
                        target="_blank"
                    >
                        <FontAwesomeIcon icon="external-link-alt" />
                        Find out more
                    </a>
                    <div className="uk-visible-small mg-margin-b-30" />
                </div>
            </MessageHolder>
        );
    } else {
        return null;
    }
}

UrlTypeInfoMessage.propTypes = {
    onClose: func.isRequired,
    visible: bool.isRequired,
};

export default UrlTypeInfoMessage;
