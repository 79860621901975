import React from 'react';
import { func, string } from 'prop-types';
import MessageHolder from 'components/messages/MessageHolder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withVisibilityLogic } from 'mangools-react-components';

function NotExistingDomainMessage(props) {
    return (
        <MessageHolder onClose={props.onClose}>
            <div className="mg-modal-header is-info">
                <FontAwesomeIcon icon="question" aria-label="Success" />
            </div>
            <div className="mg-modal-content">
                <h3 className="font-30 uk-text-bold">Domain not found</h3>
                <p className="uk-margin-top uk-margin-bottom">
                    The {props.domain} domain probably doesn&apos;t exist. Please try something else.
                </p>
                <button
                    className="mg-btn is-blue is-small uk-text-bold mg-margin-t-15"
                    onClick={props.onClose}
                    type="button"
                >
                    Ok, got it
                </button>
            </div>
        </MessageHolder>
    );
}

NotExistingDomainMessage.propTypes = {
    onClose: func.isRequired,
    domain: string.isRequired,
};

export default withVisibilityLogic(NotExistingDomainMessage);
