export const READY = 'READY';
export const PROCESSING = 'PROCESSING';
export const WAITING = 'WAITING';
export const EXPIRED = 'EXPIRED';

export const ExportStates = {
    [READY]: {
        title: 'Download',
        iconProps: {
            icon: 'download',
        },
        stateColor: 'is-green',
    },
    [PROCESSING]: {
        title: 'Processing',
        iconProps: { icon: 'spinner', spin: true },
        stateColor: 'is-blue',
    },
    [WAITING]: {
        title: 'Waiting',
        iconProps: {
            icon: 'hourglass-start',
        },
        stateColor: 'is-lightgrey',
    },
    [EXPIRED]: {
        title: 'Download expired',
        stateColor: 'is-lightgrey',
    },
};
